import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import Modal from 'react-modal'
import GaugrContext from './app/helpers/gaugrContext'
import NotificationSystem from 'react-notification-system'
import MainLoadingWrapper from './app/helpers/mainLoadingWrapper'
import ApolloWrapper from './app/helpers/apolloWrapper'
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks'
import App from './app'
import './style/style.scss'


setDefaultBreakpoints([
  { xs: 0 },
  { s: 376 },
  { m: 600 },
  { l: 769 },
  { xldown: 950 },
  { xlup: 951 }
]);

Modal.setAppElement('#ready');

const Index = () => {

  const notificationSystem = useRef(null)
  const [orgID, setOrgID] = useState(null)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)

  const domain = process.env.AUTH0_DOMAIN
  const appID = process.env.AUTH0_CLIENTID
  const redirectUri = process.env.ENV_MODE === 'production' ? process.env.AUTH0_REDIRECTURI : process.env.LOCAL_REDIRECTURI
  const audience = process.env.AUTH0_AUDIENCE

  const globalContext = {
    ...GaugrContext,
    appID,
    orgID,
    setOrgID,
    mobileMenu,
    toggleMenu: () => setMobileMenu(!mobileMenu),
    notify: (msg) => {
      const notification = notificationSystem.current
      if(notification) notification.addNotification({
        message: msg || 'Notification message',
        level: 'success'
      })
      else console.log('No Notification msg', notification)
    },
    openModal: (content) => {
      setModalContent(content)
      setIsOpen(true);
    },

    afterOpenModal: () => {
      // references are now sync'd and can be accessed.
      //subtitle.style.color = '#f00';
    },
    closeModal: () => {
      setIsOpen(false)
      setModalContent(null)
    }
  }

  return <Auth0Provider
          domain={domain}
          clientId={appID}
          redirectUri={redirectUri}
          responseType="token id_token"
          scope="openid profile"
          audience={audience}
          cacheLocation="localstorage"
          useRefreshTokens={true}
        >
          <BrowserRouter>
            <BreakpointProvider>

              <MainLoadingWrapper>
                <GaugrContext.Provider value={globalContext}>

                  <ApolloWrapper>
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={globalContext.closeModal}
                      contentLabel="Gaugr"
                      className={'modal'}
                      overlayClassName={'modalOverlay'}
                    >
                     {modalContent}
                    </Modal>
                    <App />
                  </ApolloWrapper>

                  <NotificationSystem ref={notificationSystem} />

                </GaugrContext.Provider>
              </MainLoadingWrapper>

            </BreakpointProvider>
          </BrowserRouter>
        </Auth0Provider>

}

/*const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};*/

ReactDOM.render(
  <Index/>,
  document.getElementById('ready')
)
