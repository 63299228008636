import React, {Component} from 'react'
import PropTypes from 'prop-types'

class GaugeX extends Component {
	state = {
		radius: this.props.radius,
		arcStrokeWidth: this.props.arcStrokeWidth,
		tickOffset: this.props.tickOffset,
		tickStrokeWidth: this.props.tickStrokeWidth,
		tickLength: this.props.tickLength,
		miniTickLength: this.props.miniTickLength,
		miniTickStrokeWidth: this.props.miniTickStrokeWidth,
		scaleDivisionNumber: this.props.scaleDivisionNumber,
		tickLabelOffset: this.props.tickLabelOffset,
		centralCircleRadius: this.props.centralCircleRadius,
		isInnerNumbers: this.props.isInnerNumbers,
		arrowValue: this.props.arrowValue,
		marks: this.props.marks,
		ranges: this.props.ranges,
		aperture: this.props.aperture,
		contentWidth: this.props.contentWidth,
		svgContainerWidth: this.props.contentWidth,
		svgContainerHeight: this.props.svgContainerHeight
	}
	componentWillReceiveProps(next){
		if(next.arrowValue !== this.props.arrowValue){
			this.setState({
				arrowValue: next.arrowValue,
				ranges: next.ranges
			})
		}
		if(next.ranges[0].color !== this.props.ranges[0].color){
			this.setState({ranges: next.ranges})
		}
		if(next.ranges[1].color !== this.props.ranges[1].color){
			this.setState({ranges: next.ranges})
		}
		if(next.ranges[0].end !== this.props.ranges[0].end){
			this.setState({ranges: next.ranges})
		}
		if(next.ranges[0].start !== this.props.ranges[0].start){
			this.setState({ranges: next.ranges})
		}
		if(next.ranges[1].end !== this.props.ranges[1].end){
			this.setState({ranges: next.ranges})
		}
		if(next.ranges[1].start !== this.props.ranges[1].start){
			this.setState({ranges: next.ranges})
		}
	}

	handleChange(event) {
		this.setState({
			radius: event.target.radius,
			arcStrokeWidth: event.target.arcStrokeWidth,
			tickOffset: event.target.tickOffset,
			tickStrokeWidth: event.target.tickStrokeWidth,
			tickLength: event.target.tickLength,
			miniTickLength: event.target.miniTickLength,
			miniTickStrokeWidth: event.target.miniTickStrokeWidth,
			scaleDivisionNumber: event.target.scaleDivisionNumber,
			tickLabelOffset: event.target.tickLabelOffset,
			centralCircleRadius: event.target.centralCircleRadius,
			isInnerNumbers: event.target.isInnerNumbers,
			arrowValue: event.target.arrowValue,
			marks: event.target.marks,
			ranges: event.target.ranges,
			aperture: event.target.aperture,
			contentWidth: event.target.contentWidth,
			svgContainerWidth: event.target.svgContainerWidth,
			svgContainerHeight: event.target.svgContainerHeight
		})
	}

	render() {
		let gaugeRadius = this.state.radius
		let gaugeArcStrokeWidth = this.state.arcStrokeWidth
		let gaugeTickOffset = this.state.tickOffset
		let gaugeTickStrokeWidth = this.state.tickStrokeWidth
		let gaugeTickLength = this.state.tickLength
		let gaugeMiniTickLength = this.state.miniTickLength
		let gaugeMiniTickStrokeWidth = this.state.miniTickStrokeWidth
		let gaugeScaleDivisionNumber = this.state.scaleDivisionNumber
		let gaugeTickLabelOffset = this.state.tickLabelOffset
		let gaugeCentralCircleRadius = this.state.centralCircleRadius
		let gaugeInnerNumbers = this.state.isInnerNumbers
		let gaugeArrowValue = this.state.arrowValue
		let gaugeMarks = this.state.marks
		let gaugeRanges = this.state.ranges
		let gaugeAperture = this.state.aperture
		let gaugeContentWidth = this.state.contentWidth

		let width = gaugeContentWidth
		let height = gaugeContentWidth

		let center = {
			x: width / 2,
			y: height / 2
		}

		let gaugeStart = -180 + gaugeAperture / 2
		let gaugeEnd = 180 - gaugeAperture / 2

		let gaugeLength = gaugeEnd - gaugeStart

		let polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
			let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0

			return {
				x: centerX + (radius * Math.cos(angleInRadians)),
				y: centerY + (radius * Math.sin(angleInRadians))
			}
		}

		let getArcs = () => {
			let drawArc = (startAngle, endAngle, color, index) => {
				let describeArc = (x, y, radius, startAngle, endAngle) => {
					let start = polarToCartesian(x, y, radius, endAngle)
					let end = polarToCartesian(x, y, radius, startAngle)

					let arcSweep = endAngle - startAngle <= 180 ? "0" : "1"

					let d = [
						"M", start.x, start.y,
						"A", radius, radius, 0, arcSweep, 0, end.x, end.y
					].join(" ")

					return d
				}

				return React.createElement('path', {
					className: "gauge-arc-" + index,
					key: index,
					fill: "none",
					stroke: color,
					strokeWidth: gaugeArcStrokeWidth,
					d: describeArc(center.x, center.y, gaugeRadius, startAngle, endAngle)
				})
			}

			let arcs = []

			gaugeRanges.forEach( (range, i) => {
				arcs.push(
					drawArc(gaugeStart + range.start * gaugeLength, gaugeStart + range.end * gaugeLength, range.color, i)
				)
			})
			return arcs
		}

		const getTicks = () => {
			let result = []

			let indexValue = 0

			gaugeMarks.forEach( (mark, index, array) => {
				if (index !== 0) {
					let miniGaugeLength = gaugeLength / (array.length - 1) / gaugeScaleDivisionNumber

					for (var i = 1; i < gaugeScaleDivisionNumber; i++) {
						let startPoint = polarToCartesian(center.x, center.y, gaugeRadius + (gaugeInnerNumbers ? -1 : 1) * gaugeTickOffset, gaugeStart + index * gaugeLength / (array.length - 1) - miniGaugeLength * i);
						let endPoint = polarToCartesian(center.x, center.y, gaugeRadius + (gaugeInnerNumbers ? -1 : 1) * (gaugeTickOffset + gaugeMiniTickLength), gaugeStart + index * gaugeLength / (array.length - 1) - miniGaugeLength * i);

						result.push(React.createElement('line', {
							className: "gauge-mini-tick",
							key: indexValue++,
							stroke: "#ffffff",
							x1: startPoint.x,
							y1: startPoint.y,

							x2: endPoint.x,
							y2: endPoint.y,

							strokeWidth: gaugeMiniTickStrokeWidth
						}))
					}
				}
			})

			gaugeMarks.forEach( (mark, index, array) => {
				let startPoint = polarToCartesian(center.x, center.y, gaugeRadius + (gaugeInnerNumbers ? -1 : 1) * gaugeTickOffset, gaugeStart + index * gaugeLength / (array.length - 1));
				let endPoint = polarToCartesian(center.x, center.y, gaugeRadius + (gaugeInnerNumbers ? -1 : 1) * (gaugeTickOffset + gaugeTickLength), gaugeStart + index * gaugeLength / (array.length - 1));

				result.push(React.createElement('line', {
					className: "gauge-tick",
					key: indexValue++,
					stroke: "#ffffff",
					x1: startPoint.x,
					y1: startPoint.y,

					x2: endPoint.x,
					y2: endPoint.y,

					strokeWidth: gaugeTickStrokeWidth
				}))
			})
			return result
		}

		const getLabels = () => {
			let result = []

			let indexValue = 0
			gaugeMarks.forEach( (mark, index, array) => {
				let labelCoords = polarToCartesian(center.x, center.y, gaugeRadius + (gaugeInnerNumbers ? -1 : 1) * (gaugeTickOffset + gaugeTickLength + gaugeTickLabelOffset), gaugeStart + index * gaugeLength / (array.length - 1));

				result.push(React.createElement('text', {
					className: "gauge-label",
					key: indexValue++,
					fill: "#666666",
					x: labelCoords.x,
					y: labelCoords.y,
					alignmentBaseline: "middle",
					textAnchor: "middle",
					strokeWidth: gaugeTickStrokeWidth
				}, mark))
			})
			return result
		}

		const getArrow = () => {
			let result = []

			let indexValue = 0

			let point1 = polarToCartesian(center.x, center.y, gaugeCentralCircleRadius / 2, gaugeStart + gaugeLength * gaugeArrowValue - 90);
			let point2 = polarToCartesian(center.x, center.y, gaugeRadius + (gaugeInnerNumbers ? -1 : 1) * (gaugeTickOffset + gaugeTickLength / 2), gaugeStart + gaugeLength * gaugeArrowValue);
			let point3 = polarToCartesian(center.x, center.y, gaugeCentralCircleRadius / 2, gaugeStart + gaugeLength * gaugeArrowValue + 90);

			let describe = [
				"M", point1.x, point1.y,
				"L", point2.x, point2.y,
				"L", point3.x, point3.y,
				"Z"
			].join(" ")

			result.push(React.createElement('path', {
				className: "gauge-arrow",
				key: indexValue++,
				fill: "black",
				d: describe
			}))

			result.push(React.createElement('circle', {
				className: "gauge-arrow",
				key: indexValue++,
				fill: "black",
				cx: center.x,
				cy: center.y,
				r: gaugeCentralCircleRadius
			}))
			return result
		}
		const getText = () => {
			let theVal = Math.round(gaugeArrowValue * 100)
			let indexValue = 0
		let result = React.createElement('text', {
			className: "gauge-score",
			key: indexValue++,
			fill: "#000000",
			x: 75,
			y: 105,
			fontSize: 18,
			fontWeight:'bold',
			fontFamily: 'arial',
			textAnchor: 'middle'
		}, `${theVal}%`);
		return result
    }
		return (
			React.createElement('svg', {
					className: "gauge",
					width: this.state.svgContainerWidth,
					height: this.state.svgContainerHeight,
					shapeRendering: "geometricPrecision"
				},
				getArcs(),
				getTicks(),
				getArrow(),
				//getText(),
			)
		)
	}
}


GaugeX.propTypes = {
	radius: PropTypes.number,
	arcStrokeWidth: PropTypes.number,
	tickOffset: PropTypes.number,
	tickStrokeWidth: PropTypes.number,
	tickLength: PropTypes.number,
	miniTickLength: PropTypes.number,
	miniTickStrokeWidth: PropTypes.number,
	scaleDivisionNumber: PropTypes.number,
	tickLabelOffset: PropTypes.number,
	centralCircleRadius: PropTypes.number,
	isInnerNumbers: PropTypes.bool,
	arrowValue: PropTypes.number,
	marks: PropTypes.array,
	ranges: PropTypes.arrayOf(PropTypes.shape({
		start: PropTypes.number.isRequired,
		end: PropTypes.number.isRequired,
		color: PropTypes.string.isRequired
	})).isRequired,
	aperture: PropTypes.number,
	contentWidth: PropTypes.number,
	svgContainerWidth: PropTypes.number,
	svgContainerHeight: PropTypes.number
}

GaugeX.defaultProps = {
	radius: 57,
	arcStrokeWidth: 12,
	tickOffset: -13,
	tickStrokeWidth: 4,
	tickLength: 8,
	miniTickLength: 0,
	miniTickStrokeWidth: 1,
	scaleDivisionNumber: 14,
	tickLabelOffset: 10,
	centralCircleRadius: 7,
	isInnerNumbers: false,
	arrowValue: 0,
	marks: [0, 15, 25, 50, 65, 75, 100],
	ranges: [{
		start: 0,
		end: 4.5 / 6,
		color: "#991364"
	}, {
		start: 4.5 / 6,
		end: 1,
		color: "#BCBCBC"
	}],
	aperture: 100,
	contentWidth: 150,
	svgContainerWidth: 150,
	svgContainerHeight: 130
}

export default GaugeX
