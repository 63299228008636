import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Breakpoint } from 'react-socks'
import moment from 'moment'
import { GAUGE } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import EntryMain from '../entries/entryMain'
import EditButton from '../resources/stack/editButton'
import Categories from '../resources/gaugeDisplay/categories'
import Loader from '../../../helpers/loader'

const ViewGauge = ({match}) => {

  window.scrollTo(0, 0)

  const {orgID, notify} = useContext(GaugrContext)

  const id = match.params && match.params.id ? match.params.id : null

    //console.log('Viewing Connection match data // ', match )

  if(!id) return (<div className="gauge view error">
    Gauge not found.
  </div>)

  const { loading, error, data } = useQuery(GAUGE, {variables: {id} })

  if(loading) return (<Loader class1="loading"/>)

  if(error) { console.error(error); return(<div className="gauge view error">
    Error, please try again.
  </div>)}

  //console.log('Gauge data loaded ', data)

  const {
    gauge
  } = data

  //const createdAtDisplay = moment(parseInt(createdAt)).format('M/D/YY')
  //console.log('// GAUGE VIEW ', gauge)

	return <div className="gauge view active">
    <div className="gaugeContainer">
      <div className="toolbar">
        <div className="topHeading">
          Viewing Gauge
        </div>
      </div>

      <div className="stack1">
        <div className="stackInfo">

          <div className="top">
            <div className="ico">N</div>
            <div className="mainTopic">
              <div className="heading">Your Gauge</div>
              <h1>{gauge.title}</h1>
            </div>
            <EditButton
              link={`/gauges/update/${gauge._id}`}
              tip="Edit This Gauge"
            />

            <div className="moreInfo">
              <div className="gaugeID">
                <label><b>Gauge ID</b></label>
                <input type="text" value={gauge._id}/>
                <a
                  className="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(gauge._id)
                    notify('Gauge ID copied to your clipboard.')
                  }}
                  >
                  Copy
                </a>
              </div>

              {gauge.description ? <div className="description">
                <h2>Description</h2>
                <div>{gauge.description}</div>
              </div> : null}

              <Categories
                cats={gauge.categories}
              />
            </div>

          </div>



        </div>
      </div>

    </div>

    <EntryMain
      listType="gauge"
      gaugeID={gauge._id}
    />

  </div>

}

export default ViewGauge
