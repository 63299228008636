import React, {useEffect, useState, useContext} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useApolloClient } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { VERIFY_ACCOUNT } from './helpers/queries'
import Breakpoint from 'react-socks'
import AnonRoutes from './routing/anonRoutes'
import SetupRoutes from './routing/setupRoutes'
import AuthRoutes from './routing/authRoutes'
import Nav from './pages/auth1/nav'
import Header from './pages/auth1/header'
import GaugrContext from './helpers/gaugrContext'
import Loader from './helpers/loader'


const Sidebar = () => {
  const {mobileMenu} = useContext(GaugrContext)
  let sidebarStyle = {
    display: mobileMenu ? 'block' : 'none'
  }

  return <div className={`navContainer ${mobileMenu ? 'open' : null}`}>
    <Breakpoint xlup up>
      <div className="sideBarContainer desktop">
        <Nav type="desktop"/>
      </div>
    </Breakpoint>
    <Breakpoint xldown down>
      <div className={`sideBarContainer mobile ${mobileMenu ? 'open' : null}`}>
        <Nav
          mobile={true}
          style={sidebarStyle}
        />
      </div>
    </Breakpoint>
  </div>
}

const Index = () => {


  const {
    user,
    isAuthenticated
  } = useAuth0()

  const history = useHistory()
  const location = useLocation()

  const { pathname } = location
  const client = useApolloClient()

  const {setOrgID} = useContext(GaugrContext)
  const [status, setStatus] = useState('processing')

  if(!isAuthenticated) return(
    <div className="gaugrTools anon">
      <AnonRoutes/>
    </div>
  )

  const _process = async () => {

    try{
      var data = await client.query({ query: VERIFY_ACCOUNT, variables: { uid: user.sub } })
    }
    catch(e){
      console.error(e)
      throw e
      return
    }

    //console.log('I see data ', data)

    const { data: {verifyAccount} } = data

    //console.log('Hello ', data)

    if(!verifyAccount || verifyAccount.status === 'inactive'){
      setStatus('setup')
      history.push('/')
    }

    if(verifyAccount && verifyAccount.status === 'active'){
      //console.log('Account Verified ', verifyAccount, pathname)
      //setOrgID('604115b7dc9fff094ccb89ce')
      setOrgID(verifyAccount.account._id)
      setStatus('loggedIn')
      if( pathname === '/process') history.push('/')
    }
  }

  useEffect( () => _process(), [isAuthenticated])

  let uid = user.sub
  let ux = null

  let authHeaderColor = pathname && pathname === '/' ? 'transparent' : 'white'


  const sidebar = <Sidebar/>

  switch(status){
    case 'setup':
      ux = <div className="gaugrTools">
          <SetupRoutes />
      </div>
      break
    case 'loggedIn':
      ux = <div className="gaugrTools auth">
        {sidebar}
        <div className="area">
          <Breakpoint xlup up>
            <Header
              backgroundColor={authHeaderColor}
            />
          </Breakpoint>
          <Breakpoint xldown down>
            <Header
              mobile={true}
              backgroundColor={authHeaderColor}
            />
          </Breakpoint>

          <AuthRoutes/>
        </div>
      </div>
      break
    default:
    ux = <Loader class1='gaugrTools' class2="processing"/>
  }

  return ux
}

export default Index
