import React from 'react'
import { Link } from 'react-router-dom'

const ToolMenu = ({match}) => {

window.scrollTo(0, 0)

return <div className="toolMenu">
    <ul>
      <li className="options"><span>Options</span></li>
      <li><Link to="/tool/javascript">Javascript</Link></li>
      <li><Link to="/tool/wordpress">WordPress</Link></li>
    </ul>
  </div>
}

export default ToolMenu
