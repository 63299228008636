import { gql } from '@apollo/client'

const VERIFY_ACCOUNT = gql`
  query VerifyAccount($uid: ID!) {
    verifyAccount(uid: $uid){
      status
      account{
        _id
        name,
        adminUID
        address
        city
        state
        zipcode
        website
        phone
        createdAt
        user {
          userID
          username
          firstName
          lastName
          profilePic {
            src
            handle
          }
          age
          gender
          email
          createdAt
          ethnicity
          maritalStatus
          income
        }
        billing {
          _id
          orgID
          serviceID
          service {
            _id
            name
            rate
            description
            label
          }
        }
      }
    }
  }
`
/*const GET_ORG_STATS = gql`
  query GetOrgStats($orgID: ID!) {
    getOrgStats(orgID: $orgID){
      connections {
        _id
        domain
        description
        createdAt
      }
      gauges {
        _id
        title
      }
    }
  }`*/

const UPDATE_USER = gql`
  mutation UpdateUser($data: NewUserData!){
    updateUser(data: $data) {
      userID
    }
  }
`

const UPDATE_USER_PIC = gql`
  mutation UpdateUserPic($data: NewUserPic!){
    updateUserPic(data: $data) {
      src
      handle
    }
  }
`
const MYAPPS = gql`
  query MyApps($orgID: ID!){
    myApps(orgID: $orgID){
      _id
      appID
      domain
      description
      createdAt
    }
  }
`

const GET_APP = gql`
  query GetApp($appID: ID!) {
   	getApp(appID: $appID){
      _id
      appID
      name
    	domain
      orgID
      description
      createdAt
      localTesting
      localUrl
  	}
  }
`

const CREATE_APP = gql`
  mutation CreateApp($data:NewApp!){
    createApp(data: $data) {
      _id
      appID
      name
      domain
      orgID
      description
      createdAt
      localTesting
      localUrl
    }
  }
`
const UPDATE_APP = gql`
  mutation UpdateApp($data: UpdatedApp!){
    updateApp(data: $data) {
      _id
      appID
      name
      domain
      orgID
      description
      createdAt
      localTesting
      localUrl
    }
  }
`

const UPDATE_ORG = gql`
  mutation UpdateOrg($data: UpdatedOrg!){
    updateOrg(data: $data) {
      _id
      name
      address
      city
      state
      zipcode
      website
      phone
    }
  }
`

const APP_ADDED = gql`
  subscription AppAdded($orgID: ID!){
    appAdded(orgID: $orgID){
      _id
      appID
      domain
      orgID
      description
      createdAt
    }
  }
`
const GAUGES = gql`
  query Gauges($orgID: ID!){
    gauges(orgID: $orgID){
      _id
      orgID
      title
      description
      categories {
        pos
        catID
        name
        displayName
        score
        question
      }
    }
  }
`
const GAUGE = gql`
  query Gauge($id: ID!){
    gauge(id: $id){
      _id
      orgID
      title
      description
      categories {
        pos
        catID
        name
        displayName
        score
        question
        segmentID
        weight
      }
      external
      hostedFields {
          hide_feedback
          default_gauge_value
          highlight_color
          gauge_text_color
          gauge_bar_color
          main_text_color
          header_background_color
          header_text_color
          border_color
          background_color
      }
    }
  }
`

const CREATE_GAUGE = gql`
  mutation CreateGauge($data:NewGauge!){
    createGauge(data: $data) {
      _id
      orgID
      title
      description
      categories {
        pos
        name
        displayName
        score
        question
        segmentID
        weight
      }
      external
      hostedFields {
          hide_feedback
          default_gauge_value
          highlight_color
          gauge_text_color
          gauge_bar_color
          main_text_color
          header_background_color
          header_text_color
          border_color
          background_color
      }
    }
  }
`
const UPDATE_GAUGE = gql`
  mutation UpdateGauge($data: UpdatedGauge!){
    updateGauge(data: $data) {
      _id
      orgID
      title
      description
      categories {
        pos
        catID
        name
        displayName
        score
        question
        segmentID
        weight
      }
      external
      hostedFields {
          hide_feedback
          default_gauge_value
          highlight_color
          gauge_text_color
          gauge_bar_color
          main_text_color
          header_background_color
          header_text_color
          border_color
          background_color
      }
    }
  }
`
const GAUGE_ADDED = gql`
  subscription GaugeAdded($orgID: ID!){
    gaugeAdded(orgID: $orgID){
      _id
      orgID
      title
      description
      categories {
        pos
        catID
        name
        displayName
        score
        question
        segmentID
        weight
      }
      external
      hostedFields {
          hide_feedback
          default_gauge_value
          highlight_color
          gauge_text_color
          gauge_bar_color
          main_text_color
          header_background_color
          header_text_color
          border_color
          background_color
      }
    }
  }
`
const GET_SEGMENT_MAP = gql`
query GetSegmentMap($mapRequest: MapRequest!) {
  getSegmentMap(mapRequest: $mapRequest){
      _id
      orgID
      title
      segmentAmount
      segments {
        label
        splice
        range
        range1
        range2
        marker
        weight
      }
    }
  }
`

const GET_SEGMENT_MAPS = gql`
query GetSegmentMaps($mapsRequest: MapsRequest!) {
  getSegmentMaps(mapsRequest: $mapsRequest){
      _id
      orgID
      title
      segmentAmount
      segments {
        label
        splice
        range
        range1
        range2
        marker
        weight
      }
    }
  }
`


const CREATE_SEGMENT_MAP = gql`
  mutation CreateSegmentMap($data:NewSegmentMap!){
    createSegmentMap(data: $data) {
      orgID
      title
      segmentAmount
      segments {
        label
        splice
        range
        range1
        range2
        marker
        weight
      }
    }
  }
`

const UPDATE_SEGMENT_MAP = gql`
  mutation UpdateSegmentMap($data:UpdateSegmentMap!){
    updateSegmentMap(data: $data) {
      _id
      orgID
      title
      segmentAmount
      segments {
        label
        splice
        range
        range1
        range2
        marker
        weight
      }
    }
  }
`

const GET_ENTRY = gql`
  query GetEntry($entryRequest: EntryRequest!) {
    getEntry(entryRequest: $entryRequest){
      entryID
      title
      url
      createdAt
      gaugeCount
      app{
        _id
        appID
        domain
      }
      score{
        overall
        categories{
          catID
          score
        }
        count
      }
      gauge{
        _id
        title
        categories{
          catID
          displayName
          name
          pos
          question
          weight
          segmentID
          segmentMap{
            _id
            orgID
            title
            segmentAmount
            segments {
              label
              splice
              range
              range1
              range2
              marker
              weight
            }
          }
        }
      }
    }
  }
`

const ENTRIES = gql`
  query Entries($entryParams: EntryParams!){
    entries(entryParams: $entryParams){
      _id
      entryID
      title
      url
      createdAt
      app{
        _id
        appID
        domain
      }
      score{
        overall
        categories{
          catID
          score
        }
        count
      }
      gauge{
        _id
        title
        categories{
          catID
          displayName
          name
          pos
          question
        }
      }
    }
  }
`
const ENTRY_FEEDBACK = gql`
  query entryFeedback($entryID:ID!, $offset: Int, $limit: Int){
    entryFeedback(entryID: $entryID, offset: $offset, limit: $limit){
      offset
      limit
      isComplete
      items{
        _id
        comment
        createdAt
        userID
        user{
          username
        }
        score{
          overall
          categories{
            catID
            score
          }
        }
        replies{
          _id
          user{ username }
          comment
          createdAt
        }
      }
    }
  }
`

const CREATE_XPORT = gql`
  mutation CreateXport($data:NewXport!){
    createXport(data: $data) {
      orgID
      entryID
    }
  }
`

module.exports = {
  VERIFY_ACCOUNT,
  UPDATE_USER,
  UPDATE_USER_PIC,
  UPDATE_ORG,
  MYAPPS,
  GET_APP,
  CREATE_APP,
  APP_ADDED,
  UPDATE_APP,
  GAUGE,
  GAUGES,
  CREATE_GAUGE,
  UPDATE_GAUGE,
  GAUGE_ADDED,
  GET_SEGMENT_MAP,
  GET_SEGMENT_MAPS,
  CREATE_SEGMENT_MAP,
  UPDATE_SEGMENT_MAP,
  ENTRIES,
  GET_ENTRY,
  ENTRY_FEEDBACK,
  CREATE_XPORT
}
