import React, {useContext, useEffect} from 'react'
import { useQuery } from '@apollo/client'
import {Link} from 'react-router-dom'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
//import { UPDATE_ORG } from '../../../helpers/queries'
import EditButton from '../resources/stack/editButton'
import GaugrContext from '../../../helpers/gaugrContext'
import Loader from '../../../helpers/loader'

const ViewOrg = ({data: {verifyAccount: {account: org}}}) => {

  console.log('Viewing Org Data ', org)


    window.scrollTo(0, 0)

    //console.log('Viewing Connection match data // ', match )

    //console.log('App data loaded ', data)

    const {
      _id,
      name,
      address,
      city,
      state,
      zipcode,
      phone,
      website,
      createdAt,
      user: {
        email
      }
    } = org

    const createdAtDisplay = moment(parseInt(createdAt)).format('M/D/YY')
    //<div className="download button"><Link to={`/tool`}>Tool Download</Link></div>

		return <div className="org view active">
      <div className="toolbar">
        <div className="topHeading">
          <Link to="/org">My Organization</Link>
        </div>
      </div>
      <div className="stack1">
        <div className="stackInfo">
          <div className="top">
            <div className="ico">K</div>
            <div className="mainTopic">
              <div className="heading">Organization:</div>
              <h1>{name}</h1>
            </div>
            <EditButton
              link={`/org/update/${_id}`}
              tip="Edit Organization Info"
            />
            <div className="moreInfo">
              <div className="email"><b>Main admin email:</b>{' '}{email}</div>
              <div className="location"><b>Location:</b>{' '}{city}, {state}</div>
            </div>
          </div>

        </div>

      </div>
    </div>
}


/*
<div className="moreInfo">
  <div className="domain"><b>App Domain Name:</b>{' '}{domain}</div>
  <div className="appID"><b>App ID:</b>{' '}{appID}</div>
  {description ? <div className="description">
    <h2>Description</h2>
    <div>{description}</div>
  </div> : null}
</div>

*/
export default ViewOrg
