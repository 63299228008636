import React, {useContext} from 'react'
import { useQuery } from '@apollo/client'
import { Line, Doughnut, Bar } from 'react-chartjs-2'
import { MYAPPS, GAUGES } from '../../helpers/queries'
import SetupApp from './resources/setupApp'
import SetupGauge from './resources/setupGauge'
import GaugrContext from '../../helpers/gaugrContext'
import Loader from '../../helpers/loader'

const Home = () => {

  const {orgID} = useContext(GaugrContext)
  const {loading, error, data} = useQuery( MYAPPS, {variables: {orgID} })

  const {
    loading: gaugesLoading,
    error: gaugesError,
    data: gaugesData
  } = useQuery(GAUGES, {variables: {orgID}})

  if(loading || gaugesLoading) return (<Loader class1="entries" class2="loading"/>)

  if(error || gaugesError) { console.error(error); return(<div className="app view error">
    Error, please try again.
  </div>)}


  //console.log('//// Dashboard Data ', data, gaugesData)

  let { myApps=null } = data
  let { gauges=null } = gaugesData

  if(myApps && !myApps.length) return <SetupApp />
  if(gauges && !gauges.length) return <SetupGauge />

  const chartData = {
    labels: ["August", "September", "October", "November", "December"],
    datasets: [
      {
        label: 'Overall Gauges',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgb(153, 19, 100)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(153, 19, 100)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: 'rgb(153, 19, 100)',
        pointHoverBorderColor: 'rgb(153, 19, 100)',
        pointHoverBorderWidth: 4,
        pointRadius: 3,
        pointHitRadius: 10,
        data: [1, 10, 5, 6, 29]
      },
      {
        label: 'User Registrations',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgb(195 195 195)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(195 195 195)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: 'rgb(195 195 195)',
        pointHoverBorderColor: 'rgb(195 195 195)',
        pointHoverBorderWidth: 4,
        pointRadius: 3,
        pointHitRadius: 10,
        data: [2, 8, 5, 8, 18]
      } // blue rgb(0 148 255)
    ]
  }

  const pieData = {
  	labels: [
  		'Male',
  		'Female',
  		'Other'
  	],
  	datasets: [{
  		data: [31, 18, 5],
  		backgroundColor: [
  		'#36A2EB',
  		'#991364',
  		'#cacc94'
  		],
  		hoverBackgroundColor: [
  		'#36A2EB',
  		'#991364',
  		'#cacc94'
  		]
  	}]
  };

  const barData = {
    labels: ['Wow Factor', 'Taste', 'Potency', 'Presentation'],
    datasets: [
      {
        label: 'Highest Ranking Categories',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [90, 75, 80, 71]
      }
    ]
  }
// orgID = 5fa20323e76eb679f7fb4cb9
  let temp = '1123'
  /*const dashboard = orgID === '5fa20323e76eb679f7fb4cb9' ?  <div className="dashboardArea">
    <div className="panel1">
      <div className="panel1Heading">
        <h1><span className="ico">W</span>Gauge Performance</h1>
        <span>Overall Gauge Count Statistics</span>
      </div>

      <Line data={chartData} />


    </div>

    <div className="panel2">
      <div className="section1">
        <Doughnut
          data={pieData}
          height={250}
          options={{
            segmentShowStroke: false,
            legend: {
              display: true,
              position: 'top',
            },
            title: {
              display: true,
              text: 'Overall gender stats',
              fontSize: 18,
              position: 'top',
            }
          }}
        />
      </div>
      <div className="section2">
        <Bar
          data={barData}
          height={250}
          options={{
            maintainAspectRatio: true
          }}
        />
      </div>
    </div>
    <div className="fillFresh"></div>
  </div>
  :
  <div className="dashboardArea-newUser">
    <h2 className="heading">
      My Dashboard
    </h2>
    <div className="process">
      Accumulating Gauge Data
    </div>
  </div>*/

  const dashboard = <div className="dashboardArea-v111">
    <h2 className="heading">
      My Dashboard
    </h2>
    <div className="process">
      Welcome!
    </div>
  </div>

  return dashboard
}

module.exports = Home
