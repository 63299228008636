import React, {useState, useContext, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useApolloClient, useMutation } from '@apollo/client'
import { VERIFY_ACCOUNT, UPDATE_USER } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import Loader from '../../../helpers/loader'

const SaveUser = ({data: {verifyAccount: {account: {user} }}}) => {

  const {user: {sub: uid}} = useAuth0()

  const {orgID, notify} = useContext(GaugrContext)
  const history = useHistory()

  const [msg, setMsg] = useState(null)
  const [formStatus, setFormStatus] = useState()
  const [showPicker, setShowPicker] = useState()
  const [profilePic, setProfilePic] = useState()


  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('Required')
      .max(60, 'Too Long!'),
    lastName: Yup.string()
      .required('Required')
      .max(60, 'Too Long!'),
    age: Yup.number()
      .required('Required'),
    gender: Yup.string()
      .required('Required'),
    ethnicity: Yup.string(),
    maritalStatus: Yup.string(),
    income: Yup.string()
  })

  const [realMutation, {data, loading, error}] = useMutation(
    UPDATE_USER,
    {
      onCompleted: (savedUser) => {
        //console.log('Saved User ', savedUser)

        notify('Your Gaugr profile has been Saved.')
        history.push(`/mygaugr`)
      }
    }
  )

  if(loading) return <Loader class1="loading"/>
  if(error === 'error') return <div className="error">
    {msg}
  </div>


  const form = <Formik
          initialValues={{
            username: user.username,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            age: user.age,
            gender: user.gender,
            ethnicity: user.ethnicity,
            maritalStatus: user.maritalStatus,
            income: user.income
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log('Saving User...')

            let newData = {...values}
            delete newData.username
            delete newData.email

            realMutation({
              variables: { data: {...newData, userID: uid }},
              refetchQueries: [{query: VERIFY_ACCOUNT, variables: {uid}, fetchPolicy: "no-cache" }],
              awaitRefetchQueries: true
            })

          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="field">
                <div className="info heading">
                  Username
                </div>
                <Field name="username" disabled={true} />
              </div>

              <div className="field">
                <div className="info heading">
                  Email Address
                </div>
                <Field name="email" disabled={true} />
              </div>

              <div className="field">
                <div className="info heading">
                  First Name
                </div>
                <Field name="firstName" placeholder="First Name" />
                <ErrorMessage name="firstName" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Last Name
                </div>
                <Field name="lastName" placeholder="Last Name" />
                <ErrorMessage name="lastName" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Your Age
                </div>
                <Field name="age" placeholder="Age" />
                <ErrorMessage name="age" component="div" className="formError" />
              </div>


              <div className="field">
                <div className="info heading">
                  Gender
                </div>
                <Field component="select" name="gender">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Field>
                <ErrorMessage name="gender" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Ethnicity
                </div>

                <Field component="select" name="ethnicity">
                  <option value="black-african-american">Black or African American</option>
                  <option value="white">White</option>
                  <option value="american-indian-alaska-native">American Indian or Alaska Native</option>
                  <option value="asian">Asian</option>
                  <option value="native-hawaiian-pacific-islander">Native Hawaiian or Other Pacific Islander</option>
                </Field>
                <ErrorMessage name="ethnicity" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Marital Status
                </div>
                <Field component="select" name="maritalStatus">
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="separated">Separated</option>
                  <option value="widowed">Widowed</option>
                </Field>
                <ErrorMessage name="maritalStatus" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Income
                </div>
                <Field component="select" name="income">
                  <option value="0-10k">$0 to $10k per year</option>
                  <option value="10k-30k">$10k to $30k per year</option>
                  <option value="30k-50k">$30k to $50k per year</option>
                  <option value="50k-100k">$50k to $100k per year</option>
                  <option value="100k-up">$100k or more per year</option>
                </Field>
                <ErrorMessage name="income" component="div" className="formError" />
              </div>

              <button type="submit" disabled={isSubmitting}>
                Save
              </button>
              <div className="cancel button">
                <Link to={`/mygaugr`}>Cancel</Link>
              </div>
            </Form>
          )}
        </Formik>


  let toolBarDisplay = <div className="toolbar">
    <div className="topHeading">
      <Link to="/mygaugr">My Gaugr Account</Link>
      <span>></span>
      <div>Edit</div>
    </div>
  </div>


  return <div className="myGaugr save">
        {toolBarDisplay}
        {form}
      </div>

}

export default SaveUser
