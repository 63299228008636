import React, {useContext} from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { VERIFY_ACCOUNT } from '../../../helpers/queries'
import Save from './save'
import View from './view'
import Loader from '../../../helpers/loader'


const Organization = ({match}) => {

	const {user: {sub: uid}} = useAuth0()

	const { loading, error, data, subscribeToMore } = useQuery( VERIFY_ACCOUNT, {variables: {uid} })

	if(loading) return(<Loader class1="loading"/>)
	if(error){ console.log(error); return(<div className="error">error</div>) }

	return <div className="orgContainer">
		<Switch>
			<Route path={`${match.url}/update`} render={routeProps => <Save data={data} {...routeProps}/>} />
			<Route path={match.url} render={routeProps => <View data={data} {...routeProps} />} />
		</Switch>
	</div>
}


export default Organization
