import React, {Component} from 'react'
import { Route, Switch } from 'react-router-dom'
import {Link} from 'react-router-dom'

const DocsMain = () => {
	window.scrollTo(0, 0)

	return <div className="docs">
		<div className="section support">
			<h1>Customer Support</h1>
			<p>
				We are here to help you integrate Gaugr Tools into your Website or App. Our assistance team is available 5 Days a week for technical support questions and general inquires.
				<br/><br/>
				Email Support:<a href="mailto:support@gaugr.com">{'Support@gaugr.com'}</a>
			</p>
		</div>

		<div className="section">
			<h1>Understanding the Gaugr Platform</h1>
			<p>
				Gaugr Tools is a In-depth Voting and User management platform that allows you to embed a Gauge on an app or website and collect feedback from users.
				<br/><br/>
				The Gaugr Tools Dashboard allows you to create new Gauges, connect multiple domain names, and place the gauge on unlimited web pages. Now you can monitor user feedback and statistics on your content.
			</p>
		</div>

		<div className="section">
			<h2>What is a Gauge?</h2>
			<p>
				A Gauge is an overall Score based on one or more categories each with it's own score. Combined with our magic sauce, this creates a Gauge Score.
				<br/><br/>
				Our Gaugr Component allows users to Register and Sign in to Gaugr from your website. They can then use your Gauge to submit feedback and important user information.
			</p>
		</div>

		<div className="section">
			<h2>What is an App?</h2>
			<p>
				 An App is a website, web application, or mobile application that is connected to Gaugr. A Domain name like www.mycompany.com could be an App. You can create apps on the My Apps screen by adding the domain name that you plan to showcase your Gauge on. We use this data to verify and secure your Gauge.
				 <br/><br/>
				 You can add unlimited Apps/Websites to your Gaugr Tools. Then you can use your Gauges on these Apps/Websites.
			</p>
		</div>

		<div className="section">
			<h2>What is an Entry?</h2>
			<p>
				 An entry is a page or piece of content that will use your Gauge. For example, a Video on a page www.mywebsite.com/newvideo.html. If you put a Gauge on this example video page, this will show up in your Entries section on the Gaugr Tools Dashboard. Here you will be able to see all interactivity on the Entry with your Gauge.
				 <br/><br/>
				 One gauge can have unlimited Entries, and each entry that uses this Gauge will have it's own Gauge Score and user feedback.
			</p>
		</div>

		<div className="section">
			<h2>About the Gauge Component</h2>
			<p>
				 The Gauge Component is our Javascript file that can be added to your website and will load your Gauge. The Component is currently only available as a Javascript library but will come in many flavors in the near future.
				 <br/><br/>
				 Once your Gauge component is <Link to="/tool">installed correctly</Link> you can view it by visiting the website page you placed it on. If your viewing the Gauge on a webpage for the first time, it will automatically create an Entry based on the "entryID" you specified in the Component code.
				 <br/><br/>
				 Testing Locally is allowed. When creating an app on Gaugr tools, you can specify that you want to allow testing locally from your machine by clicking the Local Host checkbox, and entering your local host url. It is recommended that once you go into production to disable the local host testing for security reasons.
				 <br/><br/>
				 https is required for websites using the component.
			</p>
		</div>

	</div>
}

const Docs = ({match}) => {
	return (
		<div className="docsContainer">
			<Switch>
				<Route path={match.url} render={routeProps => <DocsMain {...routeProps} />} />
			</Switch>
		</div>
	)
}

/*<Route path={`${match.url}/update/:id`} render={routeProps => <Save {...routeProps}/>} />
<Route path={`${match.url}/create`} render={routeProps => <Save {...routeProps}/>} />*/

module.exports = Docs
