import React, {Component, useContext} from 'react'
import {Link} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import ReactTooltip from 'react-tooltip'
import { MYAPPS, APP_ADDED } from '../../../../helpers/queries'
import GaugrContext from '../../../../helpers/gaugrContext'

class MyAppsDisplay extends Component {
  componentDidMount(){
    const { subscribeToMore, orgID } = this.props

    subscribeToMore({
        document: APP_ADDED,
        variables: {orgID},
        updateQuery: (prev, { subscriptionData }) => {
          console.log('//// Update Query Data, prev and subscriptionData ', prev, subscriptionData)
          if (!subscriptionData.data) return prev
          return {
            myApps: [
              subscriptionData.data.appAdded,
              ...prev.myApps
            ]
          }
        }
      })
  }
  render(){
    const { myApps, mobile, toggle, open } = this.props
    //result = url.replace(/(^\w+:|^)\/\//, '');

    let appsList = null
    let appsOptions = <div className="button"><Link to="/myapps/create"><span>Create a new app</span><span data-tip="Create an App" className="ico">@</span></Link></div>

    if(myApps && myApps.length){
      appsList = myApps.map( app => <div className="item" key={app.appID}>
        <Link
          onClick={ () => mobile ? toggle() : null }
          className="link"
          to={`/myapps/view/${app.appID}`}
          >
          {app.domain.replace(/(^\w+:|^)\/\//, '')}
        </Link>
      </div>)
    }

    let style = {
      display: open ? 'initial' : 'none'
    }

    return <div className="myAppsDisplay" style={style}>
      {appsList} {/*connectionOptions*/}
    </div>
  }
}
//<ReactTooltip />

const MyApps = ({mobile, showAppsToggle, open}) => {

    const {orgID, toggleMenu} = useContext(GaugrContext)

    const { loading, error, data, subscribeToMore } = useQuery(MYAPPS, {variables: { orgID } })

    if(loading) return <div className="loading"></div>
    if(error){ console.log(error); return <div className="error">error</div> }

    const myApps = data.myApps ? data.myApps : null
    if(myApps.length > 0) showAppsToggle()

    return <MyAppsDisplay
      orgID={orgID}
      myApps={myApps}
      subscribeToMore={subscribeToMore}
      mobile={mobile}
      toggle={toggleMenu}
      open={open}
    />

}


export default MyApps
