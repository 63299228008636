import React, {Component} from 'react'
import GaugeX from './gauge-x'

const GaugeDisplay = ({
  type='large',
  overall=0,
  showScore=true,
  extraText=null,
  highlight_color = '#991364',
  gauge_bar_color = "#BCBCBC"
}) =>
{
    let gaugeDisplay = null

    switch(type){
      case 'med':
        gaugeDisplay = <GaugeX
          arrowValue={overall/100}
          ranges={[
            {
              start: 0,
              end: overall / 100,
              color: highlight_color
            }, {
              start: overall / 100,
              end: 1,
              color: gauge_bar_color
            }
          ]}
          radius={17}
          arcStrokeWidth={8}
          tickOffset={-6}
          tickStrokeWidth={5}
          tickLength={20}
          centralCircleRadius={4}
          aperture={80}
          contentWidth={50}
          svgContainerWidth={50}
          svgContainerHeight={50}
          marks={[0, 50, 100]}
        />
        break;
      case 'large':
        gaugeDisplay = <GaugeX
          arrowValue={overall/100}
          ranges={[
            {
              start: 0,
              end: overall / 100,
              color: highlight_color
            }, {
              start: overall / 100,
              end: 1,
              color: gauge_bar_color
            }
          ]}
          radius={25}
          arcStrokeWidth={10}
          tickOffset={-6}
          tickStrokeWidth={6}
          tickLength={15}
          centralCircleRadius={6}
          aperture={100}
          contentWidth={90}
          svgContainerWidth={100}
          svgContainerHeight={80}
          marks={[0, 50, 100]}
        />
        break;
      case 'large-a1':
        gaugeDisplay = <GaugeX
          arrowValue={overall/100}
          ranges={[
            {
              start: 0,
              end: overall / 100,
              color: highlight_color
            }, {
              start: overall / 100,
              end: 1,
              color: gauge_bar_color
            }
          ]}
          radius={50}
          arcStrokeWidth={15}
          tickOffset={-10}
          tickStrokeWidth={9}
          tickLength={20}
          centralCircleRadius={9}
          aperture={100}
          contentWidth={130}
          svgContainerWidth={130}
          svgContainerHeight={130}
          marks={[0, 50, 100]}
        />
        break;
      case 'large-a':
        gaugeDisplay = <GaugeX
          arrowValue={overall/100}
          ranges={[
            {
              start: 0,
              end: overall / 100,
              color: highlight_color
            }, {
              start: overall / 100,
              end: 1,
              color: gauge_bar_color
            }
          ]}
          radius={60}
          arcStrokeWidth={18}
          tickOffset={-10}
          tickStrokeWidth={9}
          tickLength={20}
          centralCircleRadius={10}
          aperture={100}
          contentWidth={150}
          svgContainerWidth={150}
          svgContainerHeight={130}
          marks={[0, 50, 100]}
        />
        break;
      case 'large-b':
        gaugeDisplay = <GaugeX
          arrowValue={overall/100}
          ranges={[
            {
              start: 0,
              end: overall / 100,
              color: highlight_color
            }, {
              start: overall / 100,
              end: 1,
              color: gauge_bar_color
            }
          ]}
          radius={70}
          arcStrokeWidth={24}
          tickOffset={-13}
          tickStrokeWidth={15}
          tickLength={26}
          centralCircleRadius={13}
          aperture={100}
          contentWidth={180}
          svgContainerWidth={160}
          svgContainerHeight={140}
          marks={[0, 50, 100]}
        />
        break;
        case 'large-c':
          gaugeDisplay = <GaugeX
            arrowValue={overall/100}
            ranges={[
              {
                start: 0,
                end: overall / 100,
                color: highlight_color
              }, {
                start: overall / 100,
                end: 1,
                color: gauge_bar_color
              }
            ]}
            radius={80}
            arcStrokeWidth={24}
            tickOffset={-13}
            tickStrokeWidth={15}
            tickLength={26}
            centralCircleRadius={13}
            aperture={100}
            contentWidth={200}
            svgContainerWidth={200}
            svgContainerHeight={160}
            marks={[0, 50, 100]}
          />
    }

    let extraTextDisplay = extraText ?
      <div className="extraText">
        {extraText}
      </div>
    : null

    let textDisplay = showScore ?
      <div className="scoreDisplay">
        <span className="score">{overall}%</span>
      </div>
    : null

    return <div className={`gaugeDisplay ${type}`}>
      {extraTextDisplay}
      {gaugeDisplay}
      {textDisplay}
    </div>

  }
//<span className="score">{overall}%</span>

module.exports = GaugeDisplay
