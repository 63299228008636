import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import ReactTooltip from 'react-tooltip'
import { MYAPPS, GAUGES } from '../../../../helpers/queries'
import GaugrContext from '../../../../helpers/gaugrContext'

const ResourceLink = ({type, mobile}) => {

  const { user: {sub: uid}} = useAuth0()
  const {orgID, toggleMenu} = useContext(GaugrContext)

  if(type === 'app'){
    return <Link to="/myapps/create" onClick={ () => mobile ? toggleMenu() : null }>
      <span data-tip="Create a new app" className="createIco">@</span>
    </Link>
  }

  if(type === 'gauge' || type === 'gauge2'){

      let { loading, error, data } = useQuery(GAUGES, { variables: {orgID} })

      if(loading || error) return null

      const { gauges=null } = data
      // Start Real Equations.
      if(type === 'gauge'){
          return <Link to="/gauges/create" onClick={ () => mobile ? toggleMenu() : null }>
            <span data-tip="Create a new Gauge" className="createIco">@</span>
          </Link>
      }
      if(type === 'gauge2'){
        if(gauges && gauges.length){
          return <div className="update">


          </div>
        }else{
          return null
        }
      }


  }


  return null
}

export default ResourceLink
