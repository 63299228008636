import React from 'react'
import {Link} from 'react-router-dom'

module.exports = () => <div className="gauge setup">
	<span className="ico">N</span>
	<h2>Create your Gauge</h2>
	<div className="info">
		This is where the fun starts. Create your custom gauge, and embed the Gaugr Widget into your website.
	</div>
	<div className="button clickHere">
		<Link to="/gauges/create" className="text">Create A Gauge</Link>
	</div>
</div>
