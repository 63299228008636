import React, {useContext} from 'react'
import { useQuery } from '@apollo/client'
import { Link, Route, Switch } from 'react-router-dom'
import { GET_SEGMENT_MAPS } from '../../../helpers/queries'
//import ReactTooltip from 'react-tooltip'
import GaugrContext from '../../../helpers/gaugrContext'
//import SetupApp from '../resources/setupApp'
import Save from './save'
//import View from './view'
import Loader from '../../../helpers/loader'


const SegmentMapsMain = () => {

	const {orgID} = useContext(GaugrContext)
	const { loading, error, data, subscribeToMore } = useQuery(GET_SEGMENT_MAPS, {variables: {mapsRequest: {orgID} } })

		//console.log('//// Segment Maps Main ', data)

		if(loading) return(<Loader class1="loading"/>)
		if(error){ console.log(error); return(<div className="error">error</div>) }

		let segmentMapsDisplay = data.getSegmentMaps ? data.getSegmentMaps.map( map =>
			<div className="item" key={map._id}>
				<Link className="appLink" to={`/segmentmaps/update/${map._id}`}>
					{map.title}
				</Link>
				<div className="options">
					<a>
						<span data-tip="Delete This Map">Q</span>
					</a>
				</div>
			</div>
		) : null

		return <div className="apps list">
			<div className="headingContainer">
				<h1 className="heading">Segment Maps</h1>
				<div className="linkArea">
					<Link to="/segmentmaps/create">
						<span data-tip="Create Segment Map" className="createIco">Create New Map</span>
					</Link>
				</div>
			</div>
			<p>Gauges can be segmented and add more percision to your dataset.</p>
			{segmentMapsDisplay}
		</div>

}

const SegmentMaps = ({match, orgID}) =>
		<div className="appsContainer">
			<Switch>
				<Route path={`${match.url}/update/:segmentID`} render={routeProps => <Save {...routeProps}/>} />
				<Route path={`${match.url}/create`} render={routeProps => <Save {...routeProps}/>} />
				<Route path={match.url} render={routeProps => <SegmentMapsMain orgID={orgID} {...routeProps} />} />
			</Switch>
		</div>



export default SegmentMaps
