import React, {useState, useContext, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useApolloClient, useMutation } from '@apollo/client'
import { GET_SEGMENT_MAPS, GET_SEGMENT_MAP, CREATE_SEGMENT_MAP, UPDATE_SEGMENT_MAP } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import Loader from '../../../helpers/loader'

const SaveSegmentMap = ({match}) => {

  const {orgID, notify} = useContext(GaugrContext)
  const client = useApolloClient()

  const [status, setStatus] = useState('loading')
  const [type, setType] = useState('create') // create and update
  //const [app, setApp] = useState(null)
  const [msg, setMsg] = useState(null)
  const [formStatus, setFormStatus] = useState()

  const [localSegmentID, setLocalSegmentID] = useState(null)
  const [segmentGauge, setSegmentGauge] = useState(true)
  const [segmentAmount, setSegmentAmount] = useState(null)
  const [segments, setSegments] = useState([])
  const [weightStatus, setWeightStatus] = useState({}) // this is an object with matching index layers as segments, used as state manager for each segment.
  const [mapTitle, setMapTitle] = useState(null)


  const history = useHistory()

  const _process = async () => {

    let { path, params: {segmentID} } = match

    if(path === '/segmentmaps/create'){
      setType('create')
      setStatus('ready')
      return
    }

    if(path === '/segmentmaps/update/:segmentID'){
      let data = await client.query({
        query: GET_SEGMENT_MAP,
        variables: { mapRequest: {_id: segmentID, orgID} },
        fetchPolicy: "no-cache"
      })
      .catch( err => console.error(err) )

      if(!data && !data.getSegmentMap){
        setMsg('Segment Map not found')
        setStatus('error')
        return
      }

      let { data: { getSegmentMap} } = data

      setType('update')
      setLocalSegmentID(segmentID)
      setMapTitle(getSegmentMap.title)
      setSegments(getSegmentMap.segments)
      setSegmentAmount(getSegmentMap.segmentAmount)
      setStatus('ready')
      return
    }

  }

  const _loadWeightStatus = () => {
    // This loads weight status object based on existing or non-existing segment weights.
    let newStatus = {...weightStatus}

    for (let i = 0; i < segments.length; i++){

      if(segments && segments[i] && segments[i].weight){
        newStatus[`${i}`] = 'active'

      }else if(weightStatus[`${i}`] && weightStatus[`${i}`] === 'active'){
        newStatus[`${i}`] = 'active'

      }else{
        newStatus[`${i}`] = 'inactive'
      }
    }
    setWeightStatus(newStatus)
  }

  const _toggleWeightStatus = (index) => {
    let newStatus = {...weightStatus}

    if(weightStatus[`${index}`]){
      if(weightStatus[`${index}`] === 'active'){
          // Toggle weight status, clear weight in segment to null or 100 ?
          newStatus[`${index}`] = 'inactive'
          if(segments[index] && segments[index].weight){
            // remove weight from main obj (nullify)
            let newSegments = [...segments]
            newSegments[index].weight = null // ok... if null, then do not show, having 100 is a value the user might be using.
            setSegments(newSegments)
          }
      }else{
        // set as active
        newStatus[`${index}`] = 'active'
      }
      console.log('Setting New Status: ', newStatus)
      setWeightStatus(newStatus)
    }else{
      // First Time accessed.
      newStatus[`${index}`] = 'active'
      setWeightStatus(newStatus)
    }

  }

  const _segments = () => {

    if(segmentAmount > 0){
      let labels = []
      let splice = 100 / segmentAmount
      let total = splice // off top

      for (let i = 0; i < segmentAmount; i++) {
          let range1 = (total-splice)
          let range2 = total

          range1 = Math.floor( (Math.round(range1 * 100) / 100) + 1 )
          range2 = Math.floor( Math.round(range2 * 100) / 100 )

          //console.log(range1, range2)
          let isWeight = segments && segments[i] && segments[i].weight ? true : false
          labels.push({
            splice: parseInt(splice),
            marker: parseInt(total),
            range: `${range1} - ${range2}`,
            range1: range1,
            range2: range2,
            label: segments && segments[i] ? segments[i].label : null,
            weight: segments && segments[i] && segments[i].weight ? segments[i].weight : null,
          })
          total += splice
      }
      setSegments(labels)
      // On each lap through the segment, im updating the weightStatus for UI porposes.
      _loadWeightStatus()
    }
  }

  useEffect( () => {
    window.scrollTo(0, 0)
    _process()
  }, [])

  useEffect( () => {
    _segments()
  }, [segmentAmount])


  if(status === 'error') return <div className="error">
    {msg}
  </div>

  // Let's Set the Action based on the type of request  CREATE  or  EDIT
  let mutationAction = type === 'update' ? UPDATE_SEGMENT_MAP : CREATE_SEGMENT_MAP


  const [realMutation, {data, loading, error}] = useMutation(
    mutationAction,
    {
      onCompleted: (savedMap) => {
        //console.log('Saved Segment Map ', savedMap)

        notify('Segment Map Saved.')
        history.push(`/segmentmaps`)
      }
    }
  )

  if(status === 'loading') return <Loader class1="loading"/>

  const handleCheck = (event) => {
    let val = event.target.value
     setMapTitle(val)
  }

  let segHtml = null

  if(segments && segments.length > 0){
    segHtml = segments.map( (seg, index) => {

      let containerStyle = weightStatus[`${index}`] && weightStatus[`${index}`] === 'active' ? 'active' : 'inactive'

      return <div key={`id-${index}-seg`} className="label">
          <div className="field titleContainer">
            <div className="field-grid">
              <div className="range">Range: {seg.range}</div>

              <div className={`field-area field-${containerStyle}`}>

                <div className="labelInput">
                  <input
                    name={`label-${index+1}`}
                    placeholder="Add Label"
                    value={seg.label}
                    onChange={ (event) => {
                      let newSegs = [...segments]
                      newSegs[index].label = event.target.value
                      setSegments(newSegs)
                    }}
                  />
                  <div
                  onClick={ () => {
                    _toggleWeightStatus(index)
                  }}
                  className={`add-weight-button`}>
                    { weightStatus[`${index}`] && weightStatus[`${index}`] === 'active' ?
                      'Remove Weight' : 'Add Weight'
                    }
                  </div>
                </div>

                { weightStatus[`${index}`] === 'active' ?
                          <div className="weightInput">
                            <input
                              name={`weight-${index+1}`}
                              type="number"
                              step="any"
                              className="weightInput"
                              placeholder="1.0"
                              value={seg.weight}
                              onChange={ (event) => {
                                let newSegs = [...segments]
                                let newValue = event.target.value //> 100 ? 100 : event.target.value.replace(/\D/,'')
                                newSegs[index].weight = parseFloat(newValue)

                                console.log('Val ', event.target.value, 'New Val ', newValue, 'Numbered Version: ', newSegs[index].weight)
                                console.log(typeof newSegs[index].weight)
                                setSegments(newSegs)
                              }}
                            />
                          </div>
                : null }

              </div>
            </div>
          </div>
        </div>
      }
    )
  }

  let segmentsDisplay = segmentGauge && segments ? <div className="segmentLabels">
    {segHtml}
  </div> : null

  let segmentArea = <div className="segmentAreaContainer">
    <div className="field segmentAmount">
      <span className="title">Segment Amount </span>
      <input
        name="segmentAmount"
        placeholder="Number of segments"
        value={segmentAmount}
        onChange={ (event) => {
          setSegmentAmount(event.target.value)
        }}
      />

    </div>

    {segmentsDisplay}

  </div>


  const segmentContainer = <div className="segmentContainer">
      <div className="field">
        <div className="info heading">
          <span className="bold">
            Title
          </span>

          <input
            name="title"
            type="text"
            placeholder="Title"
            value={mapTitle}
            onChange={handleCheck}
          />

        </div>
      </div>

      { segmentGauge ? segmentArea : null }
  </div>


  const form =
        <Formik
          onSubmit={(values, { setSubmitting }) => {

            if(type === 'create') {

              if(!mapTitle){
                console.log('Prevent this form submission')
                return
              }

              if(mapTitle && segmentAmount > 0){
                let newSegmentMap = {
                  title: mapTitle,
                  orgID,
                  segmentAmount: parseInt(segmentAmount),
                  segments
                }
                realMutation({
                  variables: { data: {...newSegmentMap}},
                  refetchQueries: [{query: GET_SEGMENT_MAPS, variables: {mapsRequest: {orgID} } }],
                  awaitRefetchQueries: true
                })
              }

            }

            if(type === 'update'){
              if(!mapTitle){
                console.log('Prevent this form submission')
                return
              }

              if(mapTitle && segmentAmount > 0){
                let newSegmentMap = {
                  _id: localSegmentID,
                  title: mapTitle,
                  orgID,
                  segmentAmount: parseInt(segmentAmount),
                  segments
                }
                realMutation({
                  variables: { data: {...newSegmentMap}},
                  refetchQueries: [{query: GET_SEGMENT_MAPS, variables: {mapsRequest: {orgID} } }],
                  awaitRefetchQueries: true
                })
              }
            }

          }}
        >
          {({ isSubmitting }) => (
            <Form>

              {segmentContainer}

              <button type="submit" disabled={isSubmitting}>
                Save
              </button>
            </Form>
          )}
        </Formik>


  let substance = null

  let toolBarDisplay = <div className="toolbar">
    <div className="topHeading">
      <Link to="/segmentmaps">Segment Maps</Link>
      <span>></span>
      <div>{ type ==='update' ? 'Edit ' : 'Create a'} Segment Map</div>
    </div>
    <div className="topOptions">
      <div className="edit button">
        { type === 'update' ? <Link to={`/segmentmaps`}>Cancel</Link>
          : <Link to={`/segmentmaps`}>Cancel</Link> }
      </div>
    </div>
  </div>


  switch(status){
    case 'ready':
      substance = <div className="app save">
        {toolBarDisplay}

        { type === 'create' ? <div className="createSubHeading">
          You can add segments to your gauge categories. Create and save your Segment Maps, then use them on any of your gauge categories.
        </div> : null }
        {form}
      </div>
      break
    case 'loading':
      substance = <div>Loading...</div>
      break
  }
  return substance
}

export default SaveSegmentMap
