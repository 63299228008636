import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import GaugrIcon from '../../../img/gaugr-icon.png'

const Home = () => {

  const {
    loginWithRedirect
  } = useAuth0()

  return <div className="welcome">
    <img className="gaugr-icon" src={GaugrIcon} />
    <div className="title">GAUGR TOOLS</div>
    <div className="login">
      <div className="button" onClick={loginWithRedirect}>
        Login or Register
      </div>
    </div>
  </div>
}

export default Home
