import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
//import Save from './save'
import View from './view'
import EntryMain from './entryMain'


const Entries = ({match}) =>
		<div className="entriesContainer">
			<Switch>
				<Route path={`${match.url}/view/:id`} render={routeProps => <View {...routeProps}/>} />
				<Route path={match.url} render={routeProps => <EntryMain listType="all" {...routeProps} />} />
			</Switch>
		</div>


//<Route path={`${match.url}/create`} render={routeProps => <Save {...routeProps}/>} />
//<Route path={`${match.url}/update/:id`} render={routeProps => <Save {...routeProps}/>} />

export default Entries
