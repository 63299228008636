import React from 'react'
import ImgLoader from '../../img/loaders/loader2.gif'

const Loader = ({class1, class2}) => (<div className={`${class1} ${class2}`}>
  <div className="loading">
    <img src={ImgLoader} />
  </div>
</div>)



module.exports = Loader
