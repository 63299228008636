import React from 'react'
import Reply from './reply'

const UserGaugeReplies = ({replies}) => {

	if(replies){
    let repliesDisplay = replies.map(reply => <Reply key={reply._id} reply={reply}/>)

    return <div className="userGaugeReplies">
			<div className="repliesContainer">
      	{repliesDisplay}
			</div>
		</div>

  }else{
    return null
  }

}

export default UserGaugeReplies
