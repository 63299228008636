import React, {useContext} from 'react'
import { Route, Switch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { GAUGES, MYAPPS } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import Loader from '../../../helpers/loader'
import SetupGauge from '../resources/setupGauge'
import View from './view'
import Save from './save'

const GaugesMain = () => {

		const {orgID} = useContext(GaugrContext)

		const { loading, error, data, subscribeToMore } = useQuery(GAUGES, {variables: {orgID} })


		if(loading) return (<Loader class1="loading"/>)
		if(error){ console.log(error); return <div className="error">error</div> }

		if(!data.gauges.length) return(<SetupGauge />)

		let gaugesDisplay = data.gauges ? data.gauges.map( gauge =>
			<div className="item" key={gauge._id}>
				<Link className="gaugeLink" to={`/gauges/view/${gauge._id}`}>
					{gauge.title.charAt(0).toUpperCase() + gauge.title.slice(1)}
				</Link>
				<div className="options">
					<Link className="edit" to={`/gauges/update/${gauge._id}`} data-tip="Edit Gauge">
						^
					</Link>
					<a className="delete" data-tip="Feature Coming Soon">
						Q
					</a>
				</div>
			</div>
		) : null

		return <div className="gauges">
			<div className="headingContainer">
				<h1 className="heading">Gauges</h1>
				<div className="linkArea">
					<Link to="/gauges/create">
						<span data-tip="Create Gauge" className="createIco">Create New Gauge</span>
					</Link>
				</div>
			</div>
			<p>Gauges can be added to any of your Apps. Collect feedback from users in real-time.</p>
			{gaugesDisplay ? <div className="itemsContainer">
				{gaugesDisplay}
			</div> : null }
		</div>
}

const Gauges = ({match}) =>
		<div className="gaugesContainer">
			<Switch>
				<Route path={`/gauges/update/:id`} render={routeProps => <Save {...routeProps}/>} />
				<Route path={`/gauges/create`} render={routeProps => <Save {...routeProps}/>} />
				<Route path={`/gauges/view/:id`} render={routeProps => <View {...routeProps}/>} />
				<Route path={match.url} render={routeProps => <GaugesMain {...routeProps} />} />
			</Switch>
		</div>


/*<Route path={`${match.url}/update/:id`} render={routeProps => <Save {...routeProps}/>} />
<Route path={`${match.url}/create`} render={routeProps => <Save {...routeProps}/>} />*/

export default Gauges
