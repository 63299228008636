import React, {useState, useContext, useEffect} from 'react'
import { useApolloClient } from '@apollo/client'
import { Link } from 'react-router-dom'
import { GET_SEGMENT_MAPS } from '../../../../helpers/queries'
import GaugrContext from '../../../../helpers/gaugrContext'
import Loader from '../../../../helpers/loader'

const SegmentMap = ({defaultCategories, setDefaultCategories, category, closeModal, index}) => {

  const {orgID} = useContext(GaugrContext)
  const client = useApolloClient()

  const [maps, setMaps] = useState(null)
  const [isMap, setIsMap] = useState('pending')
  const [selectedMap, setSelectedMap] = useState(null)
  const [ready, setReady] = useState(false)


  const _process = async () => {

    let { data } = await client.query({
      query: GET_SEGMENT_MAPS,
      variables: {mapsRequest: {orgID} }
    })
    .catch( err => console.error(err) )


    let segmentMaps = data.getSegmentMaps

    if(!data){
      setIsMap('none')
      setReady(true)
      return
    }

    if(segmentMaps.length){
      setSelectedMap(category.segmentID)
      setMaps(segmentMaps)
      setIsMap('yes')
      setReady(true)
    }else{
      setIsMap('none')
      setReady(true)
      return
    }
  }

  /*const _segments = () => {

    if(segmentAmount > 0){
      let labels = []
      let splice = 100 / segmentAmount
      let total = splice // off top

      for (let i = 0; i < segmentAmount; i++) {
          let range1 = (total-splice)
          let range2 = total

          labels.push({
            splice: parseInt(splice),
            marker: parseInt(total),
            range: `${Math.round(range1 * 100) / 100} - ${Math.round(range2 * 100) / 100}`,
            range1: parseInt(range1),
            range2: parseInt(range2),
            label: segments && segments[i] ? segments[i].label : null
          })
          total += splice
      }

      setSegments(labels)
    }
  }*/

  const _preSave = () => {

      // Merge with Parent Categories, for Pre-save

      let newCats = [...defaultCategories]

      //newCats.forEach( (cat, index) => delete newCats[index].__typename )
      //console.log('// New Cats ', newCats)


      if(selectedMap){
        newCats[index].segmentID = selectedMap
      }else{
        newCats[index].segmentID = null
      }
      setDefaultCategories(newCats)
      closeModal()
  }

  const _cancel = () => closeModal()

  const _setSelected = (val) => {
    setSelectedMap(val)
  }

  useEffect( () => {
    _process()
  }, [])


  let segmentMapsDisplay = null


  if(isMap === 'pending' && !ready) return(<Loader className="loading"/>)

  if(isMap === 'none' && ready){
    segmentMapsDisplay = <div className="none">
        No saved Segment Maps.
        <Link className="appLink" onClick={_cancel} to={`/segmentmaps/create`}>
          Create a new Segment Map.
        </Link>
    </div>
  }


  // Interface
  if(isMap === 'yes' && maps.length > 0){
    segmentMapsDisplay = maps.map( map =>
      <div
        className={`item ${ selectedMap === map._id ? 'selected' : ''} `}
        key={map._id}
        onClick={ () => _setSelected(map._id) }
        >
        <div className="itemContainer">
          <span className="col1">{map.title}</span>
          <span className="coll2">Segments {map.segmentAmount}</span>
        </div>
      </div>
    )
    let noneButton = <div
      className={`item noneLink ${ !selectedMap ? 'selected' : ''} `}
      key="none"
      onClick={ () => _setSelected(null) }
      >
      None
    </div>
    segmentMapsDisplay.push(noneButton)
  }

  return <div className="segmentMapsDisplay">
    { maps && maps.length ? <div className="heading">Your saved segment maps</div> : null }
    {segmentMapsDisplay}
    { maps && maps.length > 0 ? <div className="options">
        <div className="button save" onClick={_preSave}>Pre-Save</div>
        <div className="button cancel" onClick={_cancel}>Cancel</div>
      </div> : null
    }
  </div>


}

export default SegmentMap
