import React, {useState} from 'react'
import { useAuth0 } from '@auth0/auth0-react'


const MainLoadingWrapper = ({ children }) => {
  const {
    isLoading,
    error,
  } = useAuth0()

  let msg = null

  if (isLoading) {
    // maybe make this as loading overlay ? I don't think whole app should blank out and go here all the time
    // maybe initially... we need more controls here.. like why does clicking login, trigger this part?
    msg = <div className={`gaugr_msgContainer`}>
      <span>Loading...</span>
    </div>
  }
  if (error) {

    let err = null

    if(error.message){
      err = <span>{error.message}</span>
    }

    msg = <div className={`gaugr_msgContainer error`}>
      {err}
    </div>
  }

  return <div className="gaugr_container">{msg}{children}</div>
}
export default MainLoadingWrapper
