import React from 'react'
import {Link} from 'react-router-dom'

module.exports = () => <div className="app setup">
	<span className="ico">V</span>
	<h2>Create an App</h2>
	<div className="info">
		Connect your app or website to the gaugr platform.
	</div>
	<div className="button clickHere">
		<Link to="/myapps/create" className="text">Create New App</Link>
	</div>
</div>
