import React, {useState} from 'react'
import { useQuery } from '@apollo/client'
import { ENTRY_FEEDBACK } from '../../../../../helpers/queries'
import UserGauge from './userGauge'
import Loader from '../../../../../helpers/loader'

const Feedback = ({ overall=0, entryID=null, gauge=null, count=0, setXportReady=null }) => {

    /*const [offset, setOffset] = useState(0)
    const [limit, setlimit] = useState(5)*/
    let offset = 0
    let limit = 5

    const { loading, error, data, fetchMore } = useQuery(
      ENTRY_FEEDBACK,
      {variables: { entryID, offset, limit } }
    )

    if(loading) return (<Loader class1="feedbackLoading" class2="loading"/>)

    if(error) { console.error(error); return(<div className="error">
      Error, please try again.
    </div>)}

    const feedback = data.entryFeedback && data.entryFeedback.items ? data.entryFeedback.items : null
    offset = data.entryFeedback.offset
    limit = data.entryFeedback.limit
    let isComplete = data.entryFeedback.isComplete


    if(feedback.length <= 0) return null

    if(setXportReady !== null) setXportReady(true)

    let userGauges = feedback.map( item =>
      <UserGauge
        item={item}
        gauge={gauge}
        key={item._id}
      />
    )

    let feedbackDisplay = userGauges ? <div className="feedbackContainer">
      {userGauges}

      { !isComplete ?
        <div
          class="loadMore"
          onClick={ () => fetchMore({
            variables: {
              offset: offset,
              limit: limit
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              //console.log('// Prev results ', prev)
              //console.log('// More results ', fetchMoreResult)

              let newData = JSON.parse(JSON.stringify(prev))
              let newItems = [...newData.entryFeedback.items, ...fetchMoreResult.entryFeedback.items]
              newData.entryFeedback.items = newItems
              newData.entryFeedback.offset = fetchMoreResult.entryFeedback.offset
              newData.entryFeedback.limit = fetchMoreResult.entryFeedback.limit
              newData.entryFeedback.isComplete = fetchMoreResult.entryFeedback.isComplete
              //console.log('/// final obj ', newData)
              return newData

              /*return Object.assign({}, prev, {
                entryFeedback: { items: [...prev.entryFeedback.items, ...fetchMoreResult.entryFeedback.items] }
              })*/
            }
          })}
        >
        <div class="ico">1</div>
        Load More
      </div>
    : null }

    </div> : null

    if(feedback.length > 0){
      return <div className="feedback">
        <div className="feedbackHeader">
          <div className="title">
            <span className="ico">Z</span>
            User Feedback
          </div>
          <div className="feedbackInfo">
          </div>
        </div>
        {feedbackDisplay}
      </div>
    }else{
        return <div className="feedbackEmpty">No User Feedback Yet</div>
    }

}

/*{count}
{ count === 1 ? ' Person Gauged This' : ' People Gauged This'}*/
export default Feedback
