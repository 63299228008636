import React, {useState, useContext, useEffect} from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import List from 'react-smooth-draggable-list'
import { ChromePicker } from 'react-color'
import Switch from 'react-ios-switch'
import { GAUGE, CREATE_GAUGE, UPDATE_GAUGE } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import GaugeDisplay from '../resources/gaugeDisplay'
import SegmentMaps from './resources/segmentMaps'
import Loader from '../../../helpers/loader'

const defaultCats = [
  {pos: 1, newPos: 1, name: '', displayName:''},
  {pos: 2, newPos: 2, name: '', displayName:''},
  {pos: 3, newPos: 3, name: '', displayName:''},
  {pos: 4, newPos: 4, name: '', displayName:''}
]

const SaveGauge = ({match}) => {

  window.scrollTo(0, 0)
  const {orgID, notify, openModal, closeModal} = useContext(GaugrContext)
  const client = useApolloClient()

  const [status, setStatus] = useState('loading')
  const [type, setType] = useState('create') // create and update
  const [gauge, setGauge] = useState(null)

  const [msg, setMsg] = useState(null)
  const [defaultCategories, setDefaultCategories] = useState(defaultCats)
  const [weightStatus, setWeightStatus] = useState({})
  const [colorStatus, setColorStatus] = useState({
    highlight_color: false,
    gauge_text_color: false,
    gauge_bar_color: false,
    main_text_color: false,
    header_background_color: false,
    header_text_color: false,
    border_color: false,
    background_color: false
  })
  const [highlight_color, set_highlight_color] = useState('#991364')
  const [gauge_text_color, set_gauge_text_color] = useState('white')
  const [default_gauge_value, set_default_gauge_value] = useState(60)
  const [gauge_bar_color, set_gauge_bar_color] = useState("#BCBCBC")


  const [formStatus, setFormStatus] = useState()

  const history = useHistory()

  const _loadWeightStatus = () => {
    // This loads weight status object based on existing or non-existing segment weights.
    let newStatus = {...weightStatus}

    for (let i = 0; i < defaultCategories.length; i++){

      if(defaultCategories[i] && defaultCategories[i].weight){
        newStatus[`${i}`] = 'active'

      }else if(weightStatus[`${i}`] && weightStatus[`${i}`] === 'active'){
        newStatus[`${i}`] = 'active'

      }else{
        newStatus[`${i}`] = 'inactive'
      }
    }
    //console.log('New Status ', newStatus)
    setWeightStatus(newStatus)
  }

  const _toggleWeightStatus = (index) => {
    let newStatus = {...weightStatus}

    if(weightStatus[`${index}`]){
      if(weightStatus[`${index}`] === 'active'){
          // Toggle weight status, clear weight in segment to null or 100 ?
          newStatus[`${index}`] = 'inactive'
          if(defaultCategories[index] && defaultCategories[index].weight){
            // remove weight from main obj (nullify)
            let newCats = [...defaultCategories]
            newCats[index].weight = null // ok... if null, then do not show, having 100 is a value the user might be using.
            setDefaultCategories(newCats)
          }
      }else{
        // set as active
        newStatus[`${index}`] = 'active'
      }
      //console.log('Setting New Status: ', newStatus)
      setWeightStatus(newStatus)
    }else{
      // First Time accessed.
      newStatus[`${index}`] = 'active'
      setWeightStatus(newStatus)
    }

  }

  const _setColorStatus = (name) => {
    let newStatus = {...colorStatus}
    newStatus[`${name}`] = newStatus[`${name}`] ? false : true
    setColorStatus(newStatus)
  }

  const _process = async () => {

    let { path, params: {id} } = match

    if(path === '/gauges/create'){
      setType('create')
      setStatus('ready')
      return
    }

    if(path === '/gauges/update/:id'){
      let { data, data: {gauge:GaugeQueryData} } = await client.query({
        query: GAUGE,
        variables: {id}
      })
      .catch( err => console.error(err) )

      if(!data || !GaugeQueryData){
        setMsg('Gauge not found')
        setStatus('error')
        return
      }

      let cats = JSON.parse(JSON.stringify(GaugeQueryData.categories)) // otherwise, object can not be altered.
      let updatedCategories = [...cats]

      updatedCategories.forEach( (item, index) => {
        updatedCategories[index].newPos = updatedCategories[index].pos
      })

      if(GaugeQueryData.hostedFields.highlight_color) set_highlight_color(GaugeQueryData.hostedFields.highlight_color)
      if(GaugeQueryData.hostedFields.gauge_text_color) set_gauge_text_color(GaugeQueryData.hostedFields.gauge_text_color)
      if(GaugeQueryData.hostedFields.gauge_bar_color) set_gauge_bar_color(GaugeQueryData.hostedFields.gauge_bar_color)
      if(GaugeQueryData.hostedFields.default_gauge_value) set_default_gauge_value(GaugeQueryData.hostedFields.default_gauge_value)

      setType('update')
      setGauge(GaugeQueryData)
      setDefaultCategories( updatedCategories.sort( (a,b) => a.pos - b.pos) )
      setStatus('ready')
      return
    }

  }



  useEffect( () => {
    _process()
  }, [])

  useEffect( () => {
    _loadWeightStatus()
  }, [defaultCategories])


  if(status === 'error') return <div className="error">
    {msg}
  </div>

  // Let's Set the Action based on the type of request  CREATE  or  EDIT
  let mutationAction = type === 'update' ? UPDATE_GAUGE : CREATE_GAUGE

  const SignupSchema = Yup.object().shape({
    title: Yup.string()
      .max(200, 'Too Long!')
      .required('Required'),
    description: Yup.string()
      .max(200, 'Too Long!'),
  })

  let items = defaultCategories

  let questionDisplay = <div className="catQuestion">+ Add Question</div>

  let colorTools = (name) => {

    let popStyle = {display: colorStatus[name] ? 'block' : 'none' }

    return <div className="col2">
      <div
        className="colorClick"
        onClick={ () => _setColorStatus(name)}
        >
        <Field
          name={name}
          render={({ field, form }) =>
              <div style={{backgroundColor:  field.value }} className="colorSelected"/>
          }
        />
      </div>

      <Field name={name} placeholder="none"/>

      <div className="popup" style={popStyle}>
        <div
          className="cover"
          onClick={ () => _setColorStatus(name)}
          />
        <Field
          name={name}
          render={({ field, form }) =>
            <ChromePicker
              width={150}
              disableAlpha={true}
              color={ field.value ? field.value : '' }
              onChangeComplete={ (i) => {
                form.setFieldValue(`${name}`, i.hex )
                if(name === 'hostedFields.highlight_color') set_highlight_color(i.hex)
                if(name === 'hostedFields.gauge_text_color') set_gauge_text_color(i.hex)
                if(name === 'hostedFields.gauge_bar_color') set_gauge_bar_color(i.hex)
              }}
              {...field}
            />
          }
        />
    </div>
  </div>
}
  const categoryFields =
    <List
      onReOrder={ (order) => {

        let newItems = order.map( (item, index) => {
          return Object.assign(items[item], {
            newPos: index+1
          })
        }).sort( (a,b) => a.pos - b.pos)

        setDefaultCategories(newItems)

      }}
      rowHeight={90}
      >
      {
        items.map((item,index) => {
          let containerStyle = weightStatus[`${index}`] && weightStatus[`${index}`] === 'active' ? 'active' : 'inactive'
          let catColor = {
            backgroundColor: highlight_color ? highlight_color: '#991364',
            color: gauge_text_color ? gauge_text_color : 'white'
          }
          const sMap = <SegmentMaps
            defaultCategories={defaultCategories}
            setDefaultCategories={setDefaultCategories}
            category={item}
            closeModal={closeModal}
            index={index}
          />

          return <List.Item>
          <div className="catContainer" key={item._id}>
            <div className="catStack1">
              <div classname="num">#{item.newPos}</div>
              <div className="catQuestion">
                <input
                  name={`category${index+1}-question`}
                  placeholder="Category Question"
                  value={item.question}
                  onChange={ (event) => {
                    let newCats = [...items]
                    newCats[index].question = event.target.value
                    setDefaultCategories(newCats)
                  }}
                />
              </div>
              <div className="catOptions">
                <div
                  style={{
                    backgroundColor: defaultCategories[index].segmentID ? 'black' : 'white',
                    color: defaultCategories[index].segmentID ? 'white' : 'grey'
                  }}
                  className="item"
                  onClick={ () => openModal(sMap) }
                  >
                  { defaultCategories[index].segmentID ? 'Segmented' : 'Segment' }
                </div>

              </div>
            </div>

            <div className={`categoryField field-${containerStyle}`}>
              <input
                name={`category${index+1}`}
                value={`${item.displayName}`}
                style={catColor}
                placeholder="Enter a category title"
                onChange={ (event) => {
                  let newCats = [...items]
                  newCats[index].displayName = event.target.value

                  let removeSpecials = newCats[index].displayName.replace(/[^\w\s]/gi, '');
                  let replaceUnderscores = removeSpecials.replace(/_/g, "-");
                  newCats[index].name  = replaceUnderscores.replace(/\s+/g, '-').toLowerCase(); // - for space

                  setDefaultCategories(newCats)
                }}
              />

              <div
              onClick={ () => {
                _toggleWeightStatus(index)
              }}
              style={catColor}
              className={`add-weight-button`}>
                { weightStatus[`${index}`] && weightStatus[`${index}`] === 'active' ?
                  'Remove Weight' : 'Add Weight'
                }
              </div>

              { weightStatus[`${index}`] === 'active' ?
                        <div className="weightInput">
                          <input
                            name={`weight-${index+1}`}
                            type="number"
                            step="any"
                            className="weightInput"
                            style={catColor}
                            placeholder="1.0"
                            value={item.weight}
                            onChange={ (event) => {
                              let newCats = [...items]
                              let newValue = event.target.value //> 100 ? 100 : event.target.value.replace(/\D/,'')
                              newCats[index].weight = parseFloat(newValue)

                              console.log('Val ', event.target.value, 'New Val ', newValue, 'Numbered Version: ', newCats[index].weight)
                              console.log(typeof newCats[index].weight)
                              setDefaultCategories(newCats)
                            }}
                          />
                        </div>
              : null }
              <div style={catColor} className="ico"><span style={catColor}>L</span></div>
            </div>
          </div>
        </List.Item>
      })
      }
    </List>


  const [realMutation, {data, loading, error}] = useMutation(
    mutationAction,
    {
      onCompleted: (savedGauge) => {
        //console.log('Saved Gauge ', savedGauge)

        let savedGaugeID = type === 'create' ?
          savedGauge.createGauge._id : savedGauge.updateGauge._id

        notify('Gauge Saved.')
        history.push(`/gauges/view/${savedGaugeID}`)
      }
    }
  )

  if(status === 'loading') return <Loader class1="loading"/>

  let hostedFields = gauge && gauge.hostedFields ? gauge.hostedFields : {}

  const form =
  <Formik
    initialValues={{
      title: type === 'update' ? gauge.title : '',
      description: type === 'update' ? gauge.description : '',
      external: type === 'update' ? gauge.external : false,
      hostedFields: {
        hide_feedback: type === 'update' ? hostedFields.hide_feedback : false,
        default_gauge_value: type === 'update' ? hostedFields.default_gauge_value : 50,
        highlight_color: type === 'update' ? hostedFields.highlight_color : '',
        gauge_text_color: type === 'update' ? hostedFields.gauge_text_color : '',
        gauge_bar_color: type === 'update' ? hostedFields.gauge_bar_color : '',
        main_text_color: type === 'update' ? hostedFields.main_text_color : '',
        header_background_color: type === 'update' ? hostedFields.header_background_color : '',
        header_text_color: type === 'update' ? hostedFields.header_text_color : '',
        border_color: type === 'update' ? hostedFields.border_color : '',
        background_color: type === 'update' ? hostedFields.background_color : ''
      }
    }}
    validationSchema={SignupSchema}
    onSubmit={(values, { setSubmitting }) => {

      let isReady = true

      items.forEach(item => {
        if(!item.displayName || !item.name) isReady = false
      })

      if(!isReady){
        setMsg('Error: Make sure all 4 categories are filled out.')
        setFormStatus('error')

        setSubmitting(false)
        setTimeout( () => setFormStatus(null), 4000)
        return
      }

      if(type === 'create'){

        //console.log('DATA ? ', values)

        //console.log('Form Create', items, values)

        realMutation({
          variables: { data: {...values, orgID, categories: items }}
        })

        let newGauge = data.createGauge ? data.createGauge : null
        if(!newGauge){ console.log('Could not find new gauge'); return null; }

        return
      }

      if(type === 'update'){
        let { _id } = gauge ? gauge : { _id: null }
        if(!_id){ console.log('No id on update'); return; }

        let newItems = [...items]
        newItems.forEach( (item,index) => {
          delete newItems[index].__typename
          delete newItems[index].score
        })
//console.log('Form Update', newItems, values)
        realMutation({
          variables: { data: {...values, _id: gauge._id, categories: newItems }},
          refetchQueries: [{query: GAUGE, variables: {id: _id} }],
          awaitRefetchQueries: true
        })

      }

    }}
  >
    {({ isSubmitting, values }) => (
      <Form>
        <div className="stack001">
          <div className="fieldsContainer">
            <div className="field titleContainer">
              <div className="info heading">
                <span className="bold">
                  Name
                </span>
              </div>
              <Field name="title" placeholder="Gauge Name" />
              <ErrorMessage name="title" component="div" className="formError" />
            </div>

            <div className="field descriptionContainer">
              <div className="info heading">
                <span className="bold">Description</span>
                (optional)
              </div>
              <Field component="textarea" rows="3" name="description" placeholder="Description about this Gauge."/>
              <ErrorMessage name="description" component="div" className="formError" />
            </div>
          </div>
          <div className="gaugeDisplayContainer">
            <GaugeDisplay
              type={'large-b'}
              overall={default_gauge_value}
              highlight_color={highlight_color}
              gauge_bar_color={gauge_bar_color}
            />
          </div>
        </div>


        <div className="catFields">
          <div className="catFieldsHeader">
            Gauge Categories
          </div>
          {categoryFields}
        </div>

        <div className="optionsHeader">
          <h2>Gauge Settings (optional)</h2>
          <p>The values you select will be the default settings for this Gauge. You can override these settings by defining variables directly in your component code.</p>
        </div>

        <div className="optionsContainer">

          <div className="field hide_feedback">
              <div className="col1">
                <span className="bold">
                  Hide Feedback (Comments)
                </span>
                <p>
                Hide comments on your components by enabling this option.
                </p>
              </div>
              <div className="col2">
                <Field
                  name="hostedFields.hide_feedback"
                  render={({ field, form }) => {
                    return (
                      <Switch
                        checked={values.hostedFields.hide_feedback}
                        onChange={checked => form.setFieldValue('hostedFields.hide_feedback', checked) }
                      />
                    );
                  }}
                />
              </div>
          </div>


          <div className="field default_gauge_value">
            <div className="col1">
              <span className="bold">
                Default Gauge Value
              </span>
              <p>
              A Number from 0 to 100. This will be the default value you want the Gauge to start with.
              <br/><br/><b>* Note: </b>If you have segment or category weights applied to this gauge, this will only set the categories as the default value and determine the overall score based on weights.
              </p>
            </div>

            <div className="col2">
              <Field
                name="hostedFields.default_gauge_value"
                render={({ field, form }) => {
                  return (
                    <input
                      placeholder="75"
                      value={field.value}
                      onChange={ (event) => {
                        let newValue = event.target.value > 100 ? 100 : event.target.value.replace(/\D/,'')
                        form.setFieldValue('hostedFields.default_gauge_value', Number(newValue))
                        set_default_gauge_value(Number(newValue))
                      }}
                    />
                  );
                }}
              />
              <ErrorMessage name="hostedFields.default_gauge_value" component="div" className="formError" />
            </div>
          </div>


          <div className="field highlight_color color">
            <div className="col1">
              <span className="bold">
                Highlight Color
              </span>
              <p>
              Sets the main highlight color of the gauge. Use it to match your brand colors.
              </p>
            </div>
            {colorTools('hostedFields.highlight_color')}
          </div>

          <div className="field gauge_text_color color">
            <div className="col1">
              <span className="bold">
                Gauge Text Color
              </span>
              <p>
              Sets the color of the Text that overlaps the bars in the Gauge colored areas.
              </p>
            </div>
            {colorTools('hostedFields.gauge_text_color')}
          </div>




          <div className="field gauge_bar_color color">
            <div className="col1">
              <span className="bold">
                Gauge Bar Color
              </span>
              <p>
              Sets the color of the background bar on the gauge.
              </p>
            </div>
            {colorTools('hostedFields.gauge_bar_color')}
          </div>

          <div className="field main_text_color color">
            <div className="col1">
              <span className="bold">
                Main Text Color
              </span>
              <p>
              Sets the color of all the main text on the component and the Gauge Needle.
              </p>
            </div>
            {colorTools('hostedFields.main_text_color')}
          </div>

          <div className="field header_background_color color">
            <div className="col1">
              <span className="bold">
                Header Background Color
              </span>
              <p>
              Sets the background color of the component headers.
              </p>
            </div>
            {colorTools('hostedFields.header_background_color')}
          </div>

          <div className="field header_text_color color">
            <div className="col1">
              <span className="bold">
                Header Text Color
              </span>
              <p>
              Sets the text color of the text in the component headers.
              </p>
            </div>
            {colorTools('hostedFields.header_text_color')}
          </div>

          <div className="field border_color color">
            <div className="col1">
              <span className="bold">
                Border Color
              </span>
              <p>
              Sets the color border around a gauge component.
              </p>
            </div>
            {colorTools('hostedFields.border_color')}
          </div>

          <div className="field background_color color">
            <div className="col1">
              <span className="bold">
                Background Color
              </span>
              <p>
              Sets the background color of the component.
              </p>
            </div>
            {colorTools('hostedFields.background_color')}
          </div>

          <div className="field external">
            <div className="col1">
              <span className="bold">
                External User System
              </span>
              <p>
              Bypass the Gauge user system, and use your own way of identifying users that Gauge.
              By enabling this option, you must specify an ID for the person gauging, by adding
              an "externalID=12345678' to your component code. See Variables on Tools and Downloads page.
              </p>
            </div>
            <div className="col2">
              <Field
                name="external"
                render={({ field, form }) => {
                  return (
                    <Switch
                      checked={values.external}
                      onChange={checked => form.setFieldValue('external', checked) }
                    />
                  );
                }}
              />
            </div>
          </div>

        </div>

        { formStatus === 'error' ? <div className="formError">
          {msg}
        </div> : null }

        <div className="saveGaugeContainer">
          <button className="saveGauge" type="submit" disabled={isSubmitting}>
            Save Gauge
          </button>
        </div>
      </Form>
    )}
  </Formik>

  let toolBarDisplay = <div className="toolbar">
    <div className="topHeading">
      <Link to="/gauges">All Gauges</Link>
      <span>></span>
      <div>{ type ==='update' ? 'Edit ' : 'Create '} Gauge</div>
    </div>
    <div className="topOptions">
      <div className="edit button">
        { type === 'update' ? <Link to={`/gauges/view/${gauge._id}`}>Cancel</Link>
          : <Link to={`/gauges`}>Cancel</Link> }
      </div>
    </div>
  </div>

  let substance = null
  //console.log('//// cats: ', defaultCategories)

  switch(status){
    case 'ready':
      substance = <div className="gauge save">
        {toolBarDisplay}

        { type === 'create' ? <div className="createSubHeading">
        Gauges can be added to any of your Apps. Collect feedback from users in real-time.
        </div> : null }

        {form}
      </div>
      break
    case 'loading':
      substance = <Loader class1="loading"/>
      break
  }
  return substance
}


export default SaveGauge
