import React, {Component} from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../pages/anon/home'

const Routes = () =>
      <Switch>
        <Route exact path='/'
          render={routeProps => <Home {...routeProps} />}
        />
      </Switch>

export default Routes
