import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import moment from 'moment'
import Javascript from './javascript'
import Wordpress from './wordpress'


const MainView = () => {
  return <div className="tool-main">
      <div className="toolHeader">
        <h1>Gaugr Tools Component</h1>
        <p>Choose a component option</p>
        <ul>
          <li>
            <Link to="/tool/javascript">Javascript</Link>
            <span>Download the Javascript Library, and showcase a Gauge on your Web Site. Use custom fields to add special capabilities to your Gauge.</span>
          </li>
          <li className="space"></li>
          <li>
            <Link to="/tool/wordpress">WordPress</Link>
            <span>We offer a Wordpress Plugin that you can download, install on your wordpress, then use shortcode to showcase a gauge on any page or post.</span>
          </li>
        </ul>
      </div>
    </div>
}

const Tool = ({match}) => {

window.scrollTo(0, 0)

return <div className="toolContainer">
    <Switch>
      <Route path={`${match.url}/javascript`} render={routeProps => <Javascript {...routeProps}/>} />
      <Route path={`${match.url}/wordpress`} render={routeProps => <Wordpress {...routeProps}/>} />
      <Route path={match.url} render={routeProps => <MainView {...routeProps} />} />
    </Switch>
</div>

}

export default Tool
