import React, {useContext} from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../pages/auth1/home'
import Gauges from '../pages/auth1/gauges'
import SegmentMaps from '../pages/auth1/segmentMaps'
import MyApps from '../pages/auth1/myApps'
import Entries from '../pages/auth1/entries'
import Org from '../pages/auth1/org'
import MyGaugr from '../pages/auth1/myGaugr'
import Tool from '../pages/auth1/tool'
import Docs from '../pages/auth1/docs'
import Process from '../pages/process'

const Routes = () =>
      <Switch>
        <Route exact path='/' render={routeProps => <Home {...routeProps}/> } />
        <Route path='/process' render={routeProps => <Process {...routeProps} /> } />
        <Route path='/docs' render={routeProps => <Docs {...routeProps} /> } />
        <Route path='/tool' render={routeProps => <Tool {...routeProps} /> } />
        <Route path='/entries' render={routeProps => <Entries {...routeProps} />} />
        <Route path='/gauges' render={routeProps => <Gauges {...routeProps} />} />
        <Route path='/segmentmaps' render={routeProps => <SegmentMaps {...routeProps} />} />
        <Route path='/myapps' render={routeProps => <MyApps {...routeProps} />} />
        <Route path='/org' render={routeProps => <Org {...routeProps} />} />
        <Route path='/mygaugr' render={routeProps => <MyGaugr {...routeProps} />} />
      </Switch>

export default Routes
