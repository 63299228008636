import React, {useState} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@apollo/client'
import { CREATE_XPORT } from '../../../../helpers/queries'
import Loader from '../../../../helpers/loader'
//import helper from './exportHelper'


const ExportGauges = ({ orgID, entryID=null, gauge, entryTitle}) => {

  const [status, setStatus] = useState(false)
  const {user: {sub: uid}} = useAuth0()

  const [realMutation, {data, loading, error}] = useMutation(
    CREATE_XPORT,
    {
      onCompleted: (savedXport) => {
        //console.log('Created Request', savedXport)
        setStatus(true)
      }
    }
  )

  let style = {
    display: 'flex',
    padding: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    flexDirection: 'column',
    textAlign: 'center'
  }

  let content = !status ? <div class="content">
    <p>Export Gauges for <b>{entryTitle}</b></p>
    <p class="simpleText">Gauge data will be processsed and available in CSV format.</p>
    <br/>
    <div
      className="exportButton"
      onClick={() => {
        realMutation({
          variables: { data: {entryID, orgID, entryTitle, userID: uid }}
        })
      }}>
        Create CSV File
    </div>
  </div> :
  <div class="content">
    <p class="simpleText">
    Your request has been received and your file is being created.<br />
    We will notify you by email when processing is complete.
    </p>
  </div>

  return <div className="exportContainer" style={style}>
    {content}
  </div>

}

export default ExportGauges

/*
const ExportGaugesX = ({ entryID=null, gauge, entryTitle}) => {

    const offset = 0
    const limit = 1000000

    const { loading, error, data, fetchMore } = useQuery(
      ENTRY_FEEDBACK,
      {variables: { entryID, offset, limit }, fetchPolicy: "no-cache"}
    )

    if(loading) return (<Loader class1="feedbackLoading" class2="loading"/>)

    if(error) { console.error(error); return(<div className="error">
      Error, please try again.
    </div>)}

    const feedback = data.entryFeedback && data.entryFeedback.items ? data.entryFeedback.items : null

    const _processGauge = (item) => {
      // Create a processed gauge/feedbackItem containing info from various filters.
      let newItem = _.cloneDeep(item)

      let process1 = helper.formatSegmentMap(newItem, gauge)
      let process2 = helper.formatCategoriesAndScore(process1, gauge)

      return process2
    }




    //Process In To CSV Format and Display Ready Status.


    if(!feedback) return <div className="loading">No Gauge Data</div>

    let datas = []

    let formattedFeedback = []

    feedback.forEach( (item) => {
      let newItemReceived = _processGauge(item)
      formattedFeedback.push(newItemReceived)
    })

    //console.log('Formatted Feedback ', formattedFeedback)

    formattedFeedback.forEach( (feedbackItem) => {
      //console.log('Hello ? ', feedbackItem)
      let newtime = JSON.parse(feedbackItem.createdAt)
      let timestamp = new Date(newtime).toISOString()//.toDateString()
      //let timestamp = moment(1626915257249).format()
      //let timestamp = moment(feedbackItem.createdAt)
      //console.log(timestamp, feedbackItem.createdAt)
      let cleanComment = feedbackItem.comment.replace(/(\r\n|\n|\r)/gm, "");

      datas.push({
        cell1: entryID,
        cell2: entryTitle,
        cell3: gauge._id,
        cell4: gauge.title,
        cell5: feedbackItem.userID,
        cell6: feedbackItem.score.overall,
        cell7: feedbackItem.score.weightedScore,
        cell8: gauge.categories[0].displayName,
        cell9: feedbackItem.score.categories[0].score,
        cell10: feedbackItem.score.categories[0].label,
        cell11: feedbackItem.score.categories[0].range,

        cell12: feedbackItem.score.categories[0].weightedSegmentScore,
        cell13: feedbackItem.score.categories[0].weightedCategoryScore,

        cell14: gauge.categories[1].displayName,
        cell15: feedbackItem.score.categories[1].score,
        cell16: feedbackItem.score.categories[1].label,
        cell17: feedbackItem.score.categories[1].range,

        cell18: feedbackItem.score.categories[1].weightedSegmentScore,
        cell19: feedbackItem.score.categories[1].weightedCategoryScore,

        cell20: gauge.categories[2].displayName,
        cell21: feedbackItem.score.categories[2].score,
        cell22: feedbackItem.score.categories[2].label,
        cell23: feedbackItem.score.categories[2].range,

        cell24: feedbackItem.score.categories[2].weightedSegmentScore,
        cell25: feedbackItem.score.categories[2].weightedCategoryScore,

        cell26: gauge.categories[3].displayName,
        cell27: feedbackItem.score.categories[3].score,
        cell28: feedbackItem.score.categories[3].label,
        cell29: feedbackItem.score.categories[3].range,

        cell30: feedbackItem.score.categories[3].weightedSegmentScore,
        cell31: feedbackItem.score.categories[3].weightedCategoryScore,

        cell32: `${feedbackItem.comment ? '"' : ''}${cleanComment}${feedbackItem.comment ? '"' : ''}`,
        cell33: timestamp
      })
    })


//console.log('Data ? ', datas)

    const columns = [
      {
        id: 'cell1',
        displayName: 'Entry ID',
      },
      {
        id: 'cell2',
        displayName: 'Entry Title',
      },
      {
        id: 'cell3',
        displayName: 'Gauge ID',
      },
      {
        id: 'cell4',
        displayName: 'Gauge Name',
      },
      {
        id: 'cell5',
        displayName: 'User ID',
      },
      {
        id: 'cell6',
        displayName: 'Overall',
      },

      {
        id: 'cell7',
        displayName: 'Weighted Score',
      },

      {
        id: 'cell8',
        displayName: 'Category 1 Name',
      },
      {
        id: 'cell9',
        displayName: 'Category 1 Score',
      },
      {
        id: 'cell10',
        displayName: 'Category 1 Segment Label',
      },
      {
        id: 'cell11',
        displayName: 'Category 1 Segment Range',
      },


      {
        id: 'cell12',
        displayName: 'Category 1 Weighted Segment Score',
      },
      {
        id: 'cell13',
        displayName: 'Category 1 Weighted Category Score',
      },


      {
        id: 'cell14',
        displayName: 'Catrgory 2 Name',
      },
      {
        id: 'cell15',
        displayName: 'Category 2 Score',
      },
      {
        id: 'cell16',
        displayName: 'Category 2 Segment Label',
      },
      {
        id: 'cell17',
        displayName: 'Category 2 Segment Range',
      },

      {
        id: 'cell18',
        displayName: 'Category 2 Weighted Segment Score',
      },
      {
        id: 'cell19',
        displayName: 'Category 2 Weighted Category Score',
      },




      {
        id: 'cell20',
        displayName: 'Category 3 Name',
      },
      {
        id: 'cell21',
        displayName: 'Category 3 Score',
      },
      {
        id: 'cell22',
        displayName: 'Category 3 Segment Label',
      },
      {
        id: 'cell23',
        displayName: 'Category 3 Segment Range',
      },


      {
        id: 'cell24',
        displayName: 'Category 3 Weighted Segment Score',
      },
      {
        id: 'cell25',
        displayName: 'Category 3 Weighted Category Score',
      },


      {
        id: 'cell26',
        displayName: 'Category 4 Name',
      },
      {
        id: 'cell27',
        displayName: 'Category 4 Score',
      },
      {
        id: 'cell28',
        displayName: 'Category 4 Segment Label',
      },
      {
        id: 'cell29',
        displayName: 'Category 4 Segment Range',
      },


      {
        id: 'cell30',
        displayName: 'Category 4 Weighted Segment Score',
      },
      {
        id: 'cell31',
        displayName: 'Category 4 Weighted Category Score',
      },



      {
        id: 'cell32',
        displayName: 'Comment',
      },
      {
        id: 'cell33',
        displayName: 'Timestamp',
      },

    ]


    let content = <CsvDownloader
    columns={columns}
    filename={`${entryTitle}`}
    datas={datas}
    extension=".csv"
    />

    let style = {
      display: 'flex',
      padding: '20px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      flexDirection: 'column',
      textAlign: 'center'
    }


    return <div className="exportContainer" style={style}>
      <p>Your data is now available for download. CSV Format.</p>
      <br/>
      {content}
    </div>
}
*/

/*
const datas = [
  {
    cell1: 'row 1 - cell 1',
    cell2: 'row 1 - cell 2',
  },
  {
    cell1: 'row 2 - cell 1',
    cell2: 'row 2 - cell 2',
  },
];

// entryID, entryTitle, gaugeID, gaugeName, userID, overall, category1Name, category1Score, category2Name, catrgory2Score, category3Name, category3Score, category4Name, category4Score, comment, timiestamp

*/
