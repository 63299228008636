import React, {useContext} from 'react'
import { useQuery } from '@apollo/client'
import { Link, Route, Switch } from 'react-router-dom'
import { MYAPPS } from '../../../helpers/queries'
import ReactTooltip from 'react-tooltip'
import GaugrContext from '../../../helpers/gaugrContext'
import SetupApp from '../resources/setupApp'
import Save from './save'
import View from './view'
import Loader from '../../../helpers/loader'


const MyAppsMain = () => {

	const {orgID} = useContext(GaugrContext)
	const { loading, error, data, subscribeToMore } = useQuery(MYAPPS, {variables: {orgID} })

		//console.log('//// My Apps Main loading apps ', data)

		if(loading) return(<Loader class1="loading"/>)
		if(error){ console.log(error); return(<div className="error">error</div>) }

		let myAppsDisplay = data.myApps ? data.myApps.map( app =>
			<div className="item" key={app.appID}>
				<Link className="appLink" to={`/myapps/view/${app.appID}`}>
					{app.domain.replace(/(^\w+:|^)\/\//, '')}
				</Link>
				<div className="options">
					<Link className="edit" to={`/myapps/update/${app.appID}`}>
						<span data-tip="Edit App Settings">^</span>
					</Link>
					<a className="delete">
						<span data-tip="Coming Soon.">Q</span>
					</a>
				</div>
			</div>
		) : null

		if(!data.myApps.length) return(<SetupApp />)

		return(<div className="apps list">
			<div className="headingContainer">
				<h1 className="heading">My Apps</h1>
				<div className="linkArea">
					<Link to="/myapps/create">
						<span data-tip="Create App" className="createIco">Create New App</span>
					</Link>
				</div>
			</div>

			<p>A listing of your connected Websites and Apps</p>
			{myAppsDisplay}
			<ReactTooltip/>
		</div>)

}

const MyApps = ({match, orgID}) =>
		<div className="appsContainer">
			<Switch>
				<Route path={`${match.url}/update/:appID`} render={routeProps => <Save {...routeProps}/>} />
				<Route path={`${match.url}/create`} render={routeProps => <Save {...routeProps}/>} />
				<Route path={`${match.url}/view/:appID`} render={routeProps => <View orgID={orgID} {...routeProps}/>} />
				<Route path={match.url} render={routeProps => <MyAppsMain orgID={orgID} {...routeProps} />} />
			</Switch>
		</div>



export default MyApps
