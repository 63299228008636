import React, {useState, useContext, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useApolloClient, useMutation } from '@apollo/client'
import { MYAPPS, GET_APP, CREATE_APP, UPDATE_APP } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import Loader from '../../../helpers/loader'

const SaveApp = ({match}) => {

  const {orgID, notify} = useContext(GaugrContext)
  const client = useApolloClient()

  const [status, setStatus] = useState('loading')
  const [type, setType] = useState('create') // create and update
  const [app, setApp] = useState(null)
  const [msg, setMsg] = useState(null)
  const [formStatus, setFormStatus] = useState()

  const history = useHistory()

  const _process = async () => {

    let { path, params: {appID} } = match

    if(path === '/myapps/create'){
      setType('create')
      setStatus('ready')
      return
    }

    if(path === '/myapps/update/:appID'){
      let { data, data: {getApp} } = await client.query({
        query: GET_APP,
        variables: {appID}
      })
      .catch( err => console.error(err) )

      if(!data || !getApp){
        setMsg('App not found')
        setStatus('error')
        return
      }

      setType('update')
      setApp(getApp)
      setStatus('ready')
      return
    }

  }

  useEffect( () => {
    window.scrollTo(0, 0)
    _process()
  }, [])


  if(status === 'error') return <div className="error">
    {msg}
  </div>

  // Let's Set the Action based on the type of request  CREATE  or  EDIT
  let mutationAction = type === 'update' ? UPDATE_APP : CREATE_APP

  const SignupSchema = Yup.object().shape({
    domain: Yup.string()
      .url()
      .required('Required'),
    description: Yup.string()
      .max(200, 'Too Long!'),
  })

  const [realMutation, {data, loading, error}] = useMutation(
    mutationAction,
    {
      onCompleted: (savedApp) => {
        console.log('Saved App ', savedApp)

        let savedAppID = type === 'create' ?
          savedApp.createApp.appID : savedApp.updateApp.appID

        notify('App Saved.')
        history.push(`/myapps/view/${savedAppID}`)
      }
    }
  )

  if(status === 'loading') return <Loader class1="loading"/>

  const form =
        <Formik
          initialValues={{
            name: type === 'update' ? app.name : '',
            domain: type === 'update' ? app.domain : '',
            description: type === 'update' ? app.description : '',
            localTesting: type === 'update' ? app.localTesting : false,
            localUrl: type === 'update' ? app.localUrl : ''
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {

            if(type === 'create') {
              realMutation({
                variables: { data: {...values, orgID }},
                refetchQueries: [{query: MYAPPS, variables: {orgID} }],
                awaitRefetchQueries: true
              })
            }

            if(type === 'update'){
              let { appID } = app ? app : { appID: null }
              if(!appID){ console.log('No id on update'); return; }

              realMutation({
                variables: { data: {...values, appID }},
                refetchQueries: [
                  {query: GET_APP, variables: {appID} },
                  {query: MYAPPS, variables: {orgID} }
                ],
                awaitRefetchQueries: true
              })
            }

          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="field">
                <div className="info heading">
                  App Name
                </div>
                <Field name="name" placeholder={`Example "Acme Corporation App"`} />
                <ErrorMessage name="name" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Domain name ( https://example.com ) *https required
                </div>
                <div className="fieldDesc">
                  Enter the domain name that you will showcase your Gauges on.
                </div>
                <Field name="domain" placeholder="Enter URL" />
                <ErrorMessage name="domain" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Local Testing Enabled ?
                </div>
                <div className="fieldDesc">
                  Allow local testing on your machine. Once your in production, disabled this option for better security protection.
                </div>

                <Field
                  name="localTesting"
                  render={({ field, form }) => {
                    return (
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={field.value}
                        {...field}
                      />
                    );
                  }}
                />
                <ErrorMessage name="localTesting" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Local Url (http://localhost:8080)
                </div>
                <div className="fieldDesc">
                  The local url to your app.
                </div>
                <Field name="localUrl" />
                <ErrorMessage name="localUrl" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="desc heading">Describe this website in a sentence or two. This helps us index your content better.</div>
                <Field component="textarea" rows="3" name="description" placeholder="Description about the website."/>
                <ErrorMessage name="description" component="div" className="formError" />
              </div>

              <button type="submit" disabled={isSubmitting}>
                Save App
              </button>
            </Form>
          )}
        </Formik>


  let substance = null

  let toolBarDisplay = <div className="toolbar">
    <div className="topHeading">
      <Link to="/myapps">All Apps</Link>
      <span>></span>
      <div>{ type ==='update' ? 'Edit ' : 'Create '} app</div>
    </div>
    <div className="topOptions">
      <div className="edit button">
        { type === 'update' ? <Link to={`/myapps/view/${app.appID}`}>Cancel</Link>
          : <Link to={`/myapps`}>Cancel</Link> }
      </div>
    </div>
  </div>


  switch(status){
    case 'ready':
      substance = <div className="app save">
        {toolBarDisplay}

        { type === 'create' ? <div className="createSubHeading">
          To use Gaugr, connect a website that will be showing a Gauge.
          We use this to verify and secure your Gauges.
        </div> : null }
        {form}
      </div>
      break
    case 'loading':
      substance = <div>Loading...</div>
      break
  }
  return substance
}

export default SaveApp
