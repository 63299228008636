import React, {useContext} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { VERIFY_ACCOUNT } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import AccountMenu from './resources/accountMenu'

const Header = ({backgroundColor='black', mobile=false}) => {
      // Dynamically check if page is dashboard and make header transparent

    const {user: {sub: uid}} = useAuth0()
    const {orgID, toggleMenu} = useContext(GaugrContext)

    const { loading, error, data } = useQuery(VERIFY_ACCOUNT, { variables: { uid } })

    if(loading) return null

    //console.log('header data ', data)
    // Secure the Account Data or Null it
    const { account } = !loading && !error && data && data.verifyAccount ?
        data.verifyAccount : { account: null }

    const name = !loading && !error && account ?
          account.name : '...'

    const firstName = !loading && !error && account ?
          account.user.firstName.charAt(0).toUpperCase() + account.user.firstName.slice(1) : '...'

    let backgroundStyle = { backgroundColor }
    let orgStyle = {
      color: backgroundColor === 'transparent' ? 'black' : '#14ad38'
    }

    const mobileMenuButton = mobile ?
      <div className="mobile_menu_button" onClick={ () => toggleMenu() }>
          <span>C</span>
      </div>
    : null

    const infoDisplay = !mobile ? <div className="info">
      <span className="orgHeadingText" style={orgStyle}></span>
      <h1>{name}</h1>
    </div>
    : <div className="info">
      {mobileMenuButton}
      <div className="col2">
        <span className="infoMobileGaugr">Gaugr Tools</span>
        <span style={orgStyle}>{name}</span>
      </div>
    </div>

    return <div className="header" style={backgroundStyle}>

      {infoDisplay}

      <div className="options">
        <div className="notification item">
          <div className="ico">
            <span>a</span>
          </div>
          <span className="text">Notifications</span>
        </div>
        <AccountMenu
          account={account}
        />
      </div>
    </div>

}

export default Header

/*

_id: ID!,
name: String!,
adminUID: String!,
address: String,
city: String,
state: String,
zipcode: Int,
website: String,
phone: String,
createdAt: String,
user: User!,
billing: Billing!

*/
