import React, {useState} from 'react'
import Breakpoint from 'react-socks'
import UserGaugeReplies from './userGaugeReplies'
import GaugeDisplay from '../../../resources/gaugeDisplay'
import moment from 'moment'
import Categories from '../../../resources/categories/display'

//import { AppContextConsumer } from '../../helpers/appContext'
//import ReplyTool from './replyTool-x'

const UserGauge = ({item, gauge}) => {

  const [status, setStatus] = useState(true)

  const _toggleReplies = () => {
    setStatus(!status)
  }


  if(!item) return null

  let time = moment(parseInt(item.createdAt)).fromNow()
  let profilePic = <img src={`https://s3-us-west-2.amazonaws.com/gaugr-public-assets/Gaugr-Symbol-500x500.jpg`}/>

    return <div className="userGauge">

      <div className="userGaugeContainer">

        <div className="row1">
          <div className="commentArea">
            <div className="userInfoArea">
              <div className="userPic">{profilePic}</div>
              <span className="username">{item.user && item.user.username ? item.user.username : 'User'}</span>
              <span className="createdAt">{time}</span>
            </div>
            <span className="comment">{item.comment}</span>
            {/* <ReplyTool userGaugeID={item._id} /> */}
          </div>

          <div className="theGauge">
              <Categories
                score={item.score}
                gauge={gauge}
                type="grid"
                highlight_color="black"
                gauge_text_color="black"
                gauge_bar_color='#e9e9e9'
              />
              <Breakpoint xlup up>
                <GaugeDisplay
                  type={'large-a1'}
                  overall={item.score.overall}
                  highlightColor={'black'}
                />
              </Breakpoint>
              <Breakpoint xldown down>
                <GaugeDisplay
                  type={'large'}
                  overall={item.score.overall}
                  highlightColor={'black'}
                />
              </Breakpoint>
          </div>
        </div>

        <div className="row2">
          { item.replies.length ?
              <div className="viewReplies">
                <div onClick={_toggleReplies} className="viewRepliesContainer">
                  <span>
                    - - View Replies ({ item.replies.length })
                  </span>
                </div>
              </div>
          : null }
          { status ?
            <UserGaugeReplies
              replies={item.replies}
            />
          : null }
        </div>
      </div>


    </div>

}
export default UserGauge
