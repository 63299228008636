import React from 'react'


// Customizable Category Display
/*

	- Each Entry is attached to a Gauge. There is also a possible Score with categories.
	- This tool can merge the two, or just represent a default Gauge.

*/
const Categories = ({
	score=null,
	gauge=null,
	type='thin', // thick, thin, grid
	highlight_color="black",
	gauge_text_color="white",
	gauge_bar_color='#e9e9e9'
	}) => {

	const { categories:gaugeCats=null } = gauge ? gauge : {categories: null }
	const { categories:scoreCats=null } = score ? score : {categories: null }

	let finalCats = []

	if(gauge && score){
		let sortedCats = gaugeCats.slice().sort( (a,b) => a.pos - b.pos)
		sortedCats.forEach( (cat,i) => {
			let element = scoreCats.find( (item) => item.catID === cat.catID )
			if(element) finalCats.push({...gaugeCats[i], score: element.score })
		})

	}else if(gauge){
		finalCats = gaugeCats.slice().sort( (a,b) => a.pos - b.pos)
	}else{
		return <div className="error">No Gauge Found</div>
	}


	let overallBackground = {backgroundColor: gauge_bar_color}
	let styleTextColor = {color: gauge_text_color}

	let catDisplay = finalCats.map( cat => {
		let styleBackground = {
			backgroundColor: highlight_color,
			width: cat.score ? `${cat.score}%` : 0
		}
		let scoreDisplay = <span className={`value ${type === 'grid' ? type : null }`}>
			{cat.score || 0}%
		</span>

		return <div key={cat.catID} className={`barContainer ${type}`}>
			<div style={styleTextColor} className="name thin">
				{cat.displayName}
				{ type !== 'grid' ? scoreDisplay : null }
			</div>
			<div style={overallBackground} className="bar">
				<div style={styleTextColor} className="name thick">
					{cat.displayName}
					<span className="value">
						{cat.score || 0}%
					</span>
				</div>
				<span
					className={`fill ${cat.name}`}
					style={styleBackground}
					>
				</span>
			</div>

			{ type === 'grid' ? scoreDisplay : null }
		</div>
	}) // end of map

	return <div className="categories">
		<div className="categoriesContainer">
				{catDisplay}
		</div>
	</div>



}

export default Categories
