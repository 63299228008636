import React, {Component} from 'react'
import { Route, Switch } from 'react-router-dom'
import Setup from '../pages/auth1/setup'

class Routes extends Component {

  render(){
    const {auth, client, check} = this.props
    return(
      <Switch>
        <Route path='/' render={routeProps =>
            <Setup {...routeProps} check={check} client={client} auth={auth}/> } />
      </Switch>
    )
  }
}

/*

*/

export default Routes;
