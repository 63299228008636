import React, {useEffect, useContext, useState} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import Breakpoint from 'react-socks'
import { GET_ENTRY } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import Loader from '../../../helpers/loader'
import Feedback from './resources/feedback'
import Categories from '../resources/categories/display'
import ExportGauges from './resources/exportGauges'

const ViewEntry = ({match}) => {

    window.scrollTo(0, 0)

    const {orgID, notify, openModal, closeModal} = useContext(GaugrContext)
    const [xPortReady, setXportReady] = useState(false)
    const history = useHistory()

    const _id = match.params && match.params.id ? match.params.id : null

    //console.log('Viewing Connection match data // ', match )

    if(!_id) return (<div className="entry view error">
      Entry not found.
    </div>)

    const { loading, error, data } = useQuery(
      GET_ENTRY,
      { variables: { entryRequest: { _id }} }
    )

    if(loading) return (<Loader class1="loading"/>)

    if(error) { console.error(error); return(<div className="entry view error">
      Error, please try again.
    </div>)}

    if(!data){ console.error('Entry Not found'); return(<div className="entry view error">
      Entry not found
    </div>)}

    let {
      getEntry,
      getEntry: {
        entryID,
        title="Entry",
        url,
        app,
        description,
        score,
        score: {overall, count},
        gauge,
        gaugeCount
      }
    } = data

    //console.log(getEntry)
    //const createdAtDisplay = moment(parseInt(createdAt)).format('M/D/YY')

    let gaugeScoreDisplay = <div className="gaugeScoreDisplay">
      <Breakpoint xlup up>
        <div className="score">
          <span>GAUGE SCORE</span>
          {overall}%
        </div>
      </Breakpoint>
      <Breakpoint xldown down>
        <div className="score">
          <span>SCORE</span>
          {overall}%
        </div>
      </Breakpoint>
    </div>

    let appDisplay = <div className="appDisplay">
      <Breakpoint xlup up>
        <div className="app">
          Entry Location - <a target="_blank" href={`${url}`}>{url}</a>
        </div>
        </Breakpoint>
      <Breakpoint xldown down>
        <div className="app">
          Entry Location - <a target="_blank" href={`${url}`}>View</a>
        </div>
      </Breakpoint>
    </div>

    let toolBarDisplay = <div className="toolbar">
      <div className="topHeading">
        <Link onClick={() => history.goBack() }>Back</Link>
        <span>></span>
        <div>Viewing Entry</div>
      </div>

    </div>


    let content = <ExportGauges
      entryID={entryID}
      gauge={gauge}
      entryTitle={title}
      orgID={orgID}
    />

		return <div className="entry view active">
      {toolBarDisplay}
      <div className="entryContainer">
        <div className="stack1">
          <div className="col1">
            <div className="title">{title}</div>
            {appDisplay}
            <div className="gauge">
              Using Gauge - <Link to={`/gauges/view/${gauge._id}`}>{gauge.title}</Link>
            </div>
            <div className="gaugeCount">Total Gauges: {gaugeCount}</div>
            { xPortReady ? <div className="export" onClick={ () => openModal(content)}>
              Export Entry Gauge Data
            </div> : null }
          </div>
          <div className="col2">
            {gaugeScoreDisplay}
          </div>
          <Categories
            score={score}
            gauge={gauge}
            type="thick"
          />
        </div>
      </div>


      <Feedback
        entryID={entryID}
        overall={overall}
        gauge={gauge}
        count={count}
        setXportReady={setXportReady}
      />
    </div>


}

export default ViewEntry
