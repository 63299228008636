import React from 'react'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

const EditButton = ({link, tip}) => {

  return <div className="edit button">
    <Link to={link}>
      <span data-tip={tip}>^</span>
    </Link>
    <ReactTooltip/>
  </div>
}


export default EditButton
