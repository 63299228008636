import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

const AccountMenu = ({account}) => {

  const {logout} = useAuth0()
  //const [displayStatus, setDisplayStatus] = useState(false)
  const toggle = () => setDisplayStatus(!displayStatus)

  const {
    name,
    user: {
      firstName,
      profilePic
    }
  } = account

  let profilePicSrc = profilePic && profilePic.src ? profilePic.src : null
  let profilePicDisplay = profilePicSrc ? <div className="userPic true">
    <img className="img" src={profilePicSrc} />
  </div>
  : <div className="userPic false">|</div>

  //{profilePicDisplay}

  //<span className="text nosl">{name}</span>
  const button = <div className="userOptions item">
    <span className="manage">
      <Link to="/org">
        #
      </Link>
    </span>
  </div>

  /*const menu = <div className="menuContainer">
    <ul>
        <li>
            <Link onClick={toggle} to="/org">Organization</Link>
        </li>
        <li>
            <Link onClick={toggle} to="/mygaugr">My Gaugr</Link>
        </li>
        <li>
            <Link to="#" onClick={toggle} onClick={logout}>Log Out</Link>
        </li>
    </ul>
    <div className="hover" onClick={toggle}>
      <span>9</span>
    </div>
  </div>*/

  return <div className="userOptionsContainer">
          {button}
        </div>
}
//{ displayStatus ? menu : null }

export default AccountMenu
