import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import Toolmenu from './resources/menu'

const Tool = () => {

window.scrollTo(0, 0)


const code2 =
`<div id="gaugrComponent"></div>
<script src="./gaugr/gaugrWebComponent-0.4.0.js"></script>
<script>
  gaugr.loadComponent({
    htmlElementID: 'gaugrComponent',
    appID: "Your App ID",
    domain: "Your App Domain",
    gaugeID: 'Your Gauge ID',
    entryID: 'Unique-identifier-for-this-entry',
    url: 'The URL for this content',
    title: 'Example Title'
  })
</script>`

const code3 =
`<html>
  <body>
    <script src="https://cdn.auth0.com/js/auth0/9.14.0/auth0.min.js"></script>
    <script type="text/javascript">
      var webAuth = new auth0.WebAuth({
        domain:       'login.gaugr.com',
        clientID:     'ENTER YOUR GAUGR APP ID HERE'
      });
      webAuth.popup.callback();
    </script>
  </body>
</html>
`


	return <div className="tool-view">
    <Toolmenu/>
    <h2>Get the Gaugr Tool Code</h2>
    <div className="choose download">
      <div className="chooseHeader">
        <span className="num">1</span>
        <span className="text">
          Download the Javascript code and place the "gaugr" folder in the root directory of your website.
          *Important: The location of the gaugr folder should be ( https://my-website.com/gaugr )
          <a className="downloadContainer" href="/downloads/gaugr.zip">
            <span className="ico">T</span>
            <span className="link">Download the Gaugr Web Component v0.4.0</span>
          </a>
        </span>
      </div>
    </div>

    <div className="choose">
      <div className="codeHeader chooseHeader">
        <span className="num">2</span>
        <span className="text">
          Paste the following code, on the page you want the Gauge tool to appear on. Be sure to change the variables to your own.
          <br/><br/>
          <b className="important">Https is required for websites usinig a Gaugr component.</b>
        </span>
      </div>
      <div className="codeContainer">
        <SyntaxHighlighter language='javascript' style={docco} customStyle={{padding:'20px'}}>{code2}</SyntaxHighlighter>
      </div>
      <span className="note">
        Note: If you are testing locally be sure that the "domain" field matches your Local URl settings in your App configuration screen. Also be sure "Local Testing" is enabled.
      </span>
    </div>

    <div className="customFields">
      <div className="choose">
        <div className="chooseHeader">
          <span className="num">3</span>
          Learn about Required and Custom Fields
        </div>
      </div>
      <div className="filter">
        <span>Field Name</span><span>Description</span>
      </div>
      <ul>
        <li>
          <span className="name">htmlElementID <i className="fieldRequired">*required</i></span>
          <span className="desc">Enter the HTML ID of the element you wish the Gauge Component to show in. Example: "my-gauge-component"</span>
        </li>
        <li>
          <span className="name">appID <i className="fieldRequired">*required</i></span>
          <span className="desc">This is the app ID of one of your domain names, this allows your Gauge to be used on this domain name. <br/><br/>You can find your appID, by visiting My Apps, and selecting your App.</span>
        </li>
        <li>
          <span className="name">domain <i className="fieldRequired">*required</i></span>
          <span className="desc">
            Enter the domain name of the app you setup in Gaugr Tools. This domain needs to match the website your showcasing the Gauge Tool on, or if localhost is enabled enter it here.
            <br/><br/>
            You can find your domain, by visiting My Apps, and selecting your App.
          </span>
        </li>
        <li>
          <span className="name">gaugeID <i className="fieldRequired">*required</i></span>
          <span className="desc">This is the ID of your Gauge. It tells the widget which gauge you want to use. <br/><br/>You can find your gaugeID, by visiting My Gauges, and selecting the Gauge you want to use.</span>
        </li>
        <li>
          <span className="name">entryID <i className="fieldRequired">*required</i></span>
          <span className="desc">This is a unique identifier of your page/content. Some web platforms will auto generate this for you. Each page that uses a Gauge should have it's own "entryID". <br/><br/>This is how Gaugr will keep track of your individual pages and there Gauge Data.</span>
        </li>
        <li>
          <span className="name">url <i className="fieldRequired">*required</i></span>
          <span className="desc">This is the web url to the page that this gauge appears on. ( Example: http://clothingshop.com/bluejacket.html ). <br/><br/>The domain name should match the domain name of the app you registered in Gaugr Tools.</span>
        </li>
        <li>
          <span className="name">title <i className="fieldRequired">*required</i></span>
          <span className="desc">The title of the page, or content on the page that user will be Gauging. This also allows you to identify your Entry in your Gaugr Tools Admin console.</span>
        </li>
        <li>
          <span className="name">hide_feedback</span>
          <span className="desc">Hides feedback/comments. (Example: hide_feedback: true)</span>
        </li>
        <li>
          <span className="name">externalID</span>
          <span className="desc">A String containing a unique ID</span>
        </li>
        <li>
          <span className="name">default_gauge_value</span>
          <span className="desc">A Number containing the value you want the Gauge to start with. "default_gauge_value:50"</span>
        </li>
        <li>
          <span className="name">highlight_color</span>
          <span className="desc">This sets the main highlight color of the widget and gauge. Use it to match your website or app.</span>
        </li>
        <li>
          <span className="name">gauge_text_color</span>
          <span className="desc">Sets the color of the Text that overlaps the bars in the Gauge colored areas.</span>
        </li>

        <li>
          <span className="name">gauge_bar_color</span>
          <span className="desc">Sets the color of the background bar on the gauge.</span>
        </li>

        <li>
          <span className="name">main_text_color</span>
          <span className="desc">Sets the color of all the main text on the widget and the Gauge Needle.</span>
        </li>

        <li>
          <span className="name">header_background_color</span>
          <span className="desc">Sets the background color of the widget headers.</span>
        </li>

        <li>
          <span className="name">header_text_color</span>
          <span className="desc">Sets the color of the text in the widget headers.</span>
        </li>

        <li>
          <span className="name">border_color</span>
          <span className="desc">Sets the color border around the widget.</span>
        </li>

        <li>
          <span className="name">background_color</span>
          <span className="desc">Sets the background color of the widget.</span>
        </li>
      </ul>
    </div>
  </div>

}

export default Tool
