import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import ReactTooltip from 'react-tooltip'
import GaugrContext from '../../../helpers/gaugrContext'
import MyApps from './resources/myApps'
import Gauges from './resources/gauges'
import ResourceLink from './resources/resourceLink'
import Breakpoint from 'react-socks'
import GaugrLogo from '../../../../img/gaugr-icon.png'

const AuthNav = ({mobile=false, style}) => {

    const {user: {sub: uid}} = useAuth0()
    const { orgID, mobileMenu, toggleMenu } = useContext(GaugrContext)
    const [showApps, setShowApps] = useState(false)
    const [showGauges, setShowGauges] = useState(false)
    const [showAppsToggle, setShowAppsToggle] = useState(false)
    const [showGaugesToggle, setShowGaugesToggle] = useState(false)

    const _toggleApps = () => setShowApps(!showApps)
    const _toggleGauges = () => setShowGauges(!showGauges)

    const _showAppsToggle = () => setShowAppsToggle(true)
    const _showGaugesToggle = () => setShowGaugesToggle(true)

    const info = mobile ? <span onClick={toggleMenu} className="beta">c</span> : null

    return <div className="sidebar" style={style}>
      <div className="sidebar-container">
        <div className="header">
          {info}
          <Link to="/" className="iconLink">
            <img className="gaugr-icon" src={GaugrLogo}/>
          </Link>
          <div className="gaugr-type">
            <Link to="/">
              <span className="gaugr-text">Gaugr Tools</span>
              <span className="gaugr-beta">
                Version
                <span className="version">{process.env.VERSION}</span>
              </span>
            </Link>
          </div>
        </div>

        <nav>
          <ul>

            <li>
              <div className="spacer1990"><span></span></div>
              <div className="sidebar-heading dashboard">
                <span className="dashboardICO ico">(</span>
                <span className="text">
                  <Link to="/" onClick={ () => mobile ? toggleMenu() : null }>Dashboard</Link>
                </span>
              </div>
            </li>

            <li>
              <div className="spacer1990"><span></span></div>
              <div className="sidebar-heading">
                <span className="gaugesICO ico">N</span>
                <span className="text">
                  <Link to="/gauges" onClick={ () => mobile ? toggleMenu() : null }>Gauges</Link>
                </span>

                <div className="toggleArea">
                  { showGaugesToggle ? <span
                    className="toggle"
                    onClick={_toggleGauges}
                    data-tip={showGauges ? "Hide Gauges" : "Show Gauges"}
                    >
                    { showGauges ? '4' : '1' }
                  </span> : null }
                </div>

              </div>
              <Gauges showGaugesToggle={_showGaugesToggle} open={showGauges} mobile={mobile} />
            </li>

            <li>
              <div className="spacer1990"><span></span></div>
              <div className="sidebar-heading library">
                <span className="segmentsICO ico">M</span>
                <span className="text">
                  <Link to="/segmentmaps" onClick={ () => mobile ? toggleMenu() : null }>Segments</Link>
                </span>
              </div>
            </li>
            
            <li>
              <div className="spacer1990"><span></span></div>
              <div className="sidebar-heading">
                <span className="myAppsICO ico">B</span>
                <span className="text">
                  <Link to="/myapps" onClick={ () => mobile ? toggleMenu() : null }>Apps</Link>
                </span>

                <div className="toggleArea">
                  { showAppsToggle ? <span
                    className="toggle"
                    onClick={_toggleApps}
                    data-tip={showApps ? "Hide Apps" : "Show Apps"}
                    >
                    { showApps ? '4' : '1' }
                  </span> : null }
                </div>
              </div>
              <MyApps showAppsToggle={_showAppsToggle} open={showApps} mobile={mobile} />
            </li>


            <li>
              <div className="spacer1990"><span></span></div>
              <div className="sidebar-heading tool">
                <span className="gaugesICO ico">n</span>
                <span className="text">
                  <Link to="/tool" onClick={ () => mobile ? toggleMenu() : null }>Tools & Downloads</Link>
                </span>
              </div>
            </li>


            <li>
              <div className="spacer1990"><span></span></div>
              <div className="sidebar-heading docs">
                <span className="gaugesICO ico">R</span>
                <span className="text">
                  <Link to="/docs" onClick={ () => mobile ? toggleMenu() : null }>Help & Info</Link>
                </span>
              </div>
            </li>

            <li>
              <div className="spacer1990"><span></span></div>
              {/*<ResourceLink type="gauge2" mobile={mobile}/>*/}
            </li>

          </ul>
        </nav>
        <ReactTooltip />
      </div>
    </div>
}

export default AuthNav
