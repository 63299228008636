import React, {useState, useContext, useEffect} from 'react'
import { useMutation } from '@apollo/client'
import {Link} from 'react-router-dom'
import { PickerOverlay } from 'filestack-react'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { VERIFY_ACCOUNT, UPDATE_USER_PIC } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import EditButton from '../resources/stack/editButton'
import Loader from '../../../helpers/loader'

const ViewAccount = ({
  data: {verifyAccount: {account: {_id: orgID, user} }},
  }) => {

    window.scrollTo(0, 0)

    console.log('Viewing User Data ', user)

    const {notify} = useContext(GaugrContext)
    const [showPicker, setShowPicker] = useState()
    const _togglePicker = () => {
      setShowPicker(!showPicker)
    }
    const {
      userID,
      username,
      firstName,
      lastName,
      profilePic,
      age,
      gender,
      email, // Disabled from edit (need another mechanism for this)
      createdAt,
      ethnicitym,
      maritalStatus,
      income
    } = user

    const createdAtDisplay = moment(parseInt(createdAt)).format('M/D/YY')


    const [realMutation, {data, loading, error}] = useMutation(
      UPDATE_USER_PIC,
      {
        onCompleted: (updatedPic) => {
          //console.log('Updated Pic ', updatedPic)
          notify('Your profile pic has been updated.')
        }
      }
    )

    if(loading) return <Loader className="loading"/>
    if(error === 'error') return <div className="error">
      {msg}
    </div>

    // User Profile Pic Setup
    let profilePicButton = <div onClick={_togglePicker} className="button">
      <span data-tip="Upload Profile Pic">_</span>
      <ReactTooltip/>
    </div>

    let profilePicSrc = profilePic && profilePic.src ? profilePic.src : null
    let profilePicDisplay = profilePicSrc ? <div className="userPic true">
      <img className="img" src={profilePicSrc} />
      {profilePicButton}
    </div>
    : <div className="userPic false">
      <span className="userIco">|</span>
      {profilePicButton}
    </div>

    const imagePicker = showPicker ? <div className="picker">
      <PickerOverlay
        apikey={'ARc2bwp2SOwV15kC1VgHSz'}
        pickerOptions={{
          accept: "image/jpeg",
          imageMin: [200,200],
          imageMax: [2000,2000],
          imageDim: [300,300],
          transformations: {
            crop: false,
            force: true,
            circle: true,
            rotate: false
          },
          onClose: _togglePicker,
          supportEmail: "techsupport@gaugr.com"
        }}
        onSuccess={(res) => {
          //console.log(res)

          let {
            filesUploaded
          } = res

          //console.log(' i see the file ', filesUploaded[0])
          let file = filesUploaded[0]
          if(!file || !file.url) return

          let src = file.url
          let handle = file.handle

          realMutation({
            variables: { data: {src, handle, userID }},
            refetchQueries: [{query: VERIFY_ACCOUNT, variables: {uid: userID} }],
            awaitRefetchQueries: true
          })
        }}
      />
    </div> : null

    // Render UI
		return <div className="myGaugr view active">
      <div className="toolbar">
        <div className="topHeading">
          <Link to="/mygaugr">My Gaugr Account</Link>
        </div>
      </div>

      <div className="stack1">
        <div className="stackInfo">
          <div className="top">
            <div className="uploadPic">
              {profilePicDisplay}
              {imagePicker}
            </div>
            <div className="mainTopic">
              <h1>{firstName}{' '}{lastName}</h1>
              <div className="username"><b>Username</b>{' '}@{username}</div>
            </div>
            <EditButton
              link={`/mygaugr/update`}
              tip="Edit Gaugr Profile"
            />
            <div className="moreInfo">
              <div className="createdAt"><b>Created </b>{' '}{createdAtDisplay}</div>
              <div className="email"><b>Email</b>{' '}{email}</div>
              <div className="age"><b>Age</b>{' '}{age}</div>
              <div className="gender"><b>Gender</b>{' '}{gender}</div>
            </div>
          </div>

        </div>
      </div>

    </div>

}


export default ViewAccount
