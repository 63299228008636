import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { ApolloClient, ApolloProvider, HttpLink, ApolloLink, InMemoryCache } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
//import App from './app'


const ApolloWrapper = ({children}) => {
  // Connect with API (Graphql endpoints via http and websocket)
  const {
    isLoading,
    error,
    user,
    isAuthenticated,
    getAccessTokenSilently
  } = useAuth0();

  const [ready, setReady] = useState(false)
  const [theClient, setTheClient] = useState(null)

  const _setReady = (e) => {
    if(e) console.log(e)
    setReady(true)
  }

  const _process = (theToken=null) => new Promise( (res,rej) => {
    const currentUser = isAuthenticated ? {
      __typename: 'CurrentUser',
      username: user.nickname,
      userID: user.sub
    } : null

    const authHeader = new ApolloLink((operation, next) => {
      operation.setContext(context => ({
        headers: {
          authorization: theToken ? `Bearer ${theToken}` : null
        }
      }))

      return next(operation);
    })


    const httpBundleLink = ApolloLink.from([
      authHeader,
      process.env.ENV_MODE === 'production' || process.env.ENV_MODE === 'staging'
          ? new HttpLink({ uri: process.env.API_URL })
          : new HttpLink({ uri: process.env.API_LOCAL_URL })
    ])

    // Create a WebSocket link:
    const GRAPHQL_ENDPOINT = process.env.ENV_MODE === 'production' || process.env.ENV_MODE === 'staging'
        ? process.env.API_WS
        : process.env.API_LOCAL_WS

    const subscriptionClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
      reconnect: true,
      connectionParams: {
        authToken: theToken ? `Bearer ${theToken}` : null
      },
    });
    const wsLink = new WebSocketLink(subscriptionClient);

    const link = ApolloLink.split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpBundleLink
    );

    const cache = new InMemoryCache({ addTypename: false })

    const client = new ApolloClient({
      //ssrForceFetchDelay: 100,
      cache,
      link: link,
      resolvers: {
        Mutation: {}
      },
      connectToDevTools: true,
    })

    setTheClient(client)
    _setReady()
    res()
    return
  })

  const _setToken = () => new Promise( (res,rej) => {
      getAccessTokenSilently({
        audience: process.env.AUTH0_AUDIENCE,
        scope: 'openid profile',
      })
      .then( theToken => _process(theToken) )
      .catch( e => _process(false) )
  })

  useEffect( () => {
    _setToken()
  }, [isAuthenticated])

  if(!ready){
    return <div> </div>
  }


  return <ApolloProvider client={theClient}>
          {children}
        </ApolloProvider>
}
//<App/>

module.exports = ApolloWrapper
