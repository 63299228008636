import React from 'react'
import moment from 'moment'
//import { AppContextConsumer } from '../../helpers/appContext'
//import ReplyTool from './replyTool-x'

const Reply = ({
			reply: {
				userGaugeID,
				user,
				createdAt,
				comment
			}
		}) => {

    moment.locale()

		let time = moment(parseInt(createdAt)).fromNow()

		//<ReplyTool userGaugeID={userGaugeID }/>

			return(
				<div className="reply">
					<div className="replyContainer">
		        <div className="userInfoArea">
		          <span className="username">{user && user.username ? user.username : 'User'}</span>
		          <span className="createdAt">{time}</span>
		        </div>
						<span className="comment">
							{comment}
						</span>

					</div>
				</div>
			)

}

export default Reply
