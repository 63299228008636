import React, {useContext, useEffect} from 'react'
import { useQuery } from '@apollo/client'
import {Link} from 'react-router-dom'
import moment from 'moment'
import { GET_APP } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import EntryMain from '../entries/entryMain'
import EditButton from '../resources/stack/editButton'
import Loader from '../../../helpers/loader'

const ViewApp = ({match}) => {

    window.scrollTo(0, 0)

    const { orgID, notify } = useContext(GaugrContext)

    let requestedAppID = match.params && match.params.appID ? match.params.appID : null

    //console.log('Viewing Connection match data // ', match )

    if(!requestedAppID) return (<div className="app view error">
      App not found.
    </div>)

    const {loading, error, data} = useQuery(GET_APP, {variables: {appID: requestedAppID}, fetchPolicy: "no-cache" })

    if(loading) return (<Loader class1="loading"/>)

    if(error) { console.error(error); return(<div className="app view error">
      Error, please try again.
    </div>)}

    //console.log('App data loaded ', data)

    const {
      getApp: {
        name,
        domain,
        description='',
        _id,
        appID,
        createdAt,
        localTesting,
        localUrl
      }
    } = data

    const createdAtDisplay = moment(parseInt(createdAt)).format('M/D/YY')
    //<div className="download button"><Link to={`/tool`}>Tool Download</Link></div>

		return <div className="app view active">
      <div className="toolbar">
        <div className="topHeading">
          <Link to="/myapps">All Apps</Link>
          <span>></span>
          <div>Viewing app</div>
        </div>
      </div>
      <div className="stack1">
        <div className="stackInfo">
          <div className="top">
            <div className="ico">V</div>
            <div className="mainTopic">
              <div className="heading">Your app</div>
              <h1>{name}</h1>
            </div>
            <EditButton
              link={`/myapps/update/${appID}`}
              tip="Edit This App"
            />
            <div className="moreInfo">
              <div className="domain">
                <b>App Domain Name:</b>{' '}
                <a href={domain} target="_blank">{domain}</a>
              </div>
              <div className="appID">
                <label><b>App ID</b></label>
                <input type="text" value={appID}/>
                <a
                  className="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(appID)
                    notify('App ID copied to your clipboard.')
                  }}
                  >
                  Copy
                </a>
              </div>
              {description ? <div className="description">
                <h2>Description</h2>
                <div>{description}</div>
              </div> : null}
            </div>
          </div>
        </div>
      </div>

      <EntryMain
        listType="app"
        appID={appID}
      />

    </div>

}

/*
<div className="createdAt">
  <h2>Created</h2>
  <div className="date">{createdAtDisplay}</div>
</div>
*/

export default ViewApp
