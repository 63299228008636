import React, {useContext} from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ENTRIES } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import Loader from '../../../helpers/loader'
//import Breadcrumbs from '../resources/breadcrumbs'
//import Save from './save'
//import View from './view'

// List View
// View all entries from appID

// View all entries from Gauge ID

const EntryMain = ({appID, gaugeID=null, listType="all"}) => {

  const {orgID} = useContext(GaugrContext)

  const { loading, error, data } = useQuery(
    ENTRIES,
    {
      variables: { entryParams: {orgID, listType, appID, gaugeID} },
      fetchPolicy: "no-cache"
    }
  )


  if(loading) return (<Loader class1="entries" class2="loading"/>)

  if(error) { console.error(error); return(<div className="app view error">
    Entries Error, please try again.
  </div>)}

  const { entries } = data

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // If Entries Exist

  let headingText = listType === 'app' ?
    'Entries from this app.' : listType === 'gauge' ?
    'Entries using this gauge' : 'All Entries'

  let heading = entries.length ? <div className="entriesHeading">
    <h2>{headingText}</h2>
  </div> : null

  let entriesList = entries.length ? entries.map( entry => {
    let scoreBarStyle = {
      position: 'absolute',
      left:0, right:0, top:0,bottom:0,
      width: `${entry.score.overall}%`,
      backgroundColor: '#991364'
    }
    return <div key={entry._id} className="entry">
      <div className="title item">
        <Link to={`/entries/view/${entry._id}`}>{entry.title}</Link>
        { entry.url ? <div className="url">{entry.url}</div> : null }
      </div>
      <div className="score item">
        <div className="overall">{entry.score.overall}%</div>
        <div className="scoreBar">
          <span className="bar" style={scoreBarStyle}></span>
        </div>
      </div>
    </div>
  }) : null

  let entriesDisplay = entriesList ? <div className="entriesDisplay">
    <div className="filter">
      <span>Title</span> <span>Score</span>
    </div>
    {entriesList}
  </div> : null


  let displayType = entries.length ? 'populated' : 'empty'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Empty Defaults
  let entriesDefault = !entries.length ? <div className="no-entries">
    <div className="ico">F</div>
    <div className="text">No entries yet.</div>
  </div> : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return <div className={`entries ${displayType}`}>
    {heading}
    {entriesDisplay}
    {entriesDefault}
  </div>

}

export default EntryMain
