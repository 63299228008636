import React, {useState, useContext, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useApolloClient, useMutation } from '@apollo/client'
import { VERIFY_ACCOUNT, UPDATE_ORG } from '../../../helpers/queries'
import GaugrContext from '../../../helpers/gaugrContext'
import Loader from '../../../helpers/loader'

const SaveOrg = () => {

  const {user: {sub: uid}} = useAuth0()

  const {orgID, notify} = useContext(GaugrContext)
  const client = useApolloClient()
  const history = useHistory()
  
  const [status, setStatus] = useState('loading')
  const [org, setOrg] = useState(null)
  const [msg, setMsg] = useState(null)
  const [formStatus, setFormStatus] = useState()


  const _process = async () => {
    let {
      data, data: {
        verifyAccount: { account }
      }
    } = await client.query({
      query: VERIFY_ACCOUNT,
      variables: {uid}
    })
    .catch( err => {
      console.error(err)
      setMsg(err)
      setStatus('error')
    })

    if(!data || !account){
      setMsg('Org not found')
      setStatus('error')
      return
    }

    setOrg(account)
    setStatus('ready')
    return
  }

  useEffect( () => {
    window.scrollTo(0, 0)
    _process()
  }, [])


  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .max(50, 'Too Long!'),
    address: Yup.string()
      .required('Required'),
    city: Yup.string()
      .required('Required'),
    state: Yup.string()
      .required('Required'),
    zipcode: Yup.number()
      .required('Required'),
    phone: Yup.string()
      .required('Required'),
    website: Yup.string().url()
  })

  const [realMutation, {data, loading, error}] = useMutation(
    UPDATE_ORG,
    {
      onCompleted: (savedOrg) => {
        console.log('Saved Org ', savedOrg)

        let savedOrgID = savedOrg.updateOrg._id

        notify('Organization Saved.')
        history.push(`/org`)
      }
    }
  )

  if(loading) return <Loader class1="loading"/>
  if(error === 'error') return <div className="error">
    {msg}
  </div>


  const form = status === 'ready' ?
        <Formik
          initialValues={{
            name: org.name,
            address: org.address,
            city: org.city,
            state: org.state,
            zipcode: org.zipcode,
            phone: org.phone,
            website: org.website
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log('Saving Org...')
            realMutation({
              variables: { data: {...values, _id: orgID }},
              refetchQueries: [{query: VERIFY_ACCOUNT, variables: {uid} }],
              awaitRefetchQueries: true
            })

          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="field">
                <div className="info heading">
                  Organization Name
                </div>
                <Field name="name" placeholder={`Example "Acme Corporation"`} />
                <ErrorMessage name="name" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Address
                </div>
                <Field name="address" placeholder="Enter Address" />
                <ErrorMessage name="address" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  City
                </div>
                <Field name="city" placeholder="City" />
                <ErrorMessage name="city" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Zipcode
                </div>
                <Field name="zipcode" placeholder="Zipcode" />
                <ErrorMessage name="zipcode" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Phone
                </div>
                <Field name="phone" placeholder="Phone" />
                <ErrorMessage name="phone" component="div" className="formError" />
              </div>

              <div className="field">
                <div className="info heading">
                  Website
                </div>
                <Field name="website" placeholder="Zipcode" />
                <ErrorMessage name="website" component="div" className="formError" />
              </div>

              <button type="submit" disabled={isSubmitting}>
                Save
              </button>
            </Form>
          )}
        </Formik>
        : null

/*
<div className="field">
  <div className="info heading">
    Address
  </div>
  <div className="fieldDesc">
    Enter the domain name that you will showcase your Gauges on.
  </div>
  <Field name="address" placeholder="Enter Address" />
  <ErrorMessage name="address" component="div" className="formError" />
</div>
*/


  let toolBarDisplay = <div className="toolbar">
    <div className="topHeading">
      <Link to="/org">Organization Home</Link>
      <span>></span>
      <div>Edit</div>
    </div>
    <div className="topOptions">
      <div className="edit button">
        <Link to={`/org/`}>Cancel</Link>
      </div>
    </div>
  </div>

  let substance = null

  switch(status){
    case 'ready':
      substance = <div className="org save">
        {toolBarDisplay}
        {form}
      </div>
      break
    case 'loading':
      substance = <div>Loading...</div>
      break
    case 'error':
      substance = <div>HELLO{msg}</div>
      break
  }
  return substance

}

export default SaveOrg
