import React, {createContext} from "react"

const GaugrContext = createContext({
  appID: null,
  orgID: null,
  setOrgID: null,
  mobileMenu: null,
  toggleMenu: null,
  notify: null
})

export default GaugrContext
