import React, {Component, useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GAUGES, GAUGE_ADDED } from '../../../../helpers/queries'
import GaugrContext from '../../../../helpers/gaugrContext'


// SIDE NAV MACHINERY FOR GAUGES

class GaugesDisplay extends Component {
  componentDidMount(){
    const { subscribeToMore, orgID } = this.props

    subscribeToMore({
        document: GAUGE_ADDED,
        variables: {orgID},
        updateQuery: (prev, { subscriptionData }) => {
          console.log('//// Update Query Data, prev and subscriptionData ', prev, subscriptionData)
          if (!subscriptionData.data) return prev
          return {
            gauges: [
              subscriptionData.data.gaugeAdded,
              ...prev.gauges
            ]
          }
        }
      })

  }
  render(){
    const { myGauges, mobile, toggle, open } = this.props
    let gaugeItems = null
    let gaugesOptions = null

    //gaugesOptions = <div className="button"><Link to="/gauges/create">Create A Gauge</Link></div>
    // No connections yet, if they delete connection, gauges will not show (for now)
    //gaugesOptions = <div className="item">To create a gauge, create an app first</div>

    if(myGauges && myGauges.length){

      gaugeItems = myGauges.map( gauge => <div className="item" key={gauge._id}>
        <Link
          className="link" to={`/gauges/view/${gauge._id}`}
          onClick={ () => mobile ? toggle() : null }
          >
          {gauge.title}
        </Link>
      </div>)
    }

    let style = {
      display: open ? 'initial' : 'none'
    }
    return <div className="gaugesDisplay" style={style}>
      {gaugeItems} {gaugesOptions}
    </div>
  }
}

const Gauges = ({mobile, showGaugesToggle, open}) => {

  const {orgID, toggleMenu} = useContext(GaugrContext)

  const { loading, error, data, subscribeToMore } = useQuery(GAUGES, {variables: {orgID} })

  if(loading) return <div className="loading"></div>
  if(error){ console.log(error); return <div className="error">error</div> }


  const myGauges = data.gauges ? data.gauges : null
  if(myGauges.length > 0) showGaugesToggle()

  return <GaugesDisplay
    orgID={orgID}
    myGauges={myGauges}
    subscribeToMore={subscribeToMore}
    mobile={mobile}
    toggle={toggleMenu}
    open={open}
  />

}




/*

// Create Gauges
let gaugesDetails = null
let gaugesOptions = null
if(connections && connections.length){
  if(service.label === 'free'){
    if(gauges.length === 0){
      gaugesOptions = <div className="button item"><Link to="/gauges/create">Create A Gauge</Link></div>
    }else{
      gaugesOptions = <div className="item">Upgrade to Gaugr Pro for more Gauges</div>
    }
  }
  // coming soon ~ PRO
}else{
  // No connections yet, if they delete connection, gauges will not show (for now)
  gaugesOptions = <div className="item">To create a gauge, create a connection first</div>
}

if(orgStats && connections && connections.length && gauges && gauges.length ){
  gaugesDetails = gauges.map( gauge => <div className="item" key={gauge._id}>
    <Link className="link" to={`/gauges/${gauge._id}`}>{gauge.title}</Link>
  </div>)
}

let gaugesDisplay = <div className="gaugesDisplay">
  {gaugesDetails} {gaugesOptions}
</div>
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

*/

export default Gauges
