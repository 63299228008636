import React from 'react'


const Categories = ({cats, type="plain"}) => {

    let catsDisplay = null

    if(!cats) return null
    // #{item.pos} -
    switch(type){
      case 'plain':
        let catsContent = cats.slice().sort( (a,b) => a.pos - b.pos)
        .map( item =>
          <div className="catsItem" key={item.catID}>
            {item.displayName}
          </div>
        )
        catsDisplay = <div className="catsDisplay">
          <h2 className="catsHeading">Gauge Categories</h2>
          {catsContent ? <div className="items">
            {catsContent}
          </div> : null }
        </div>
        break
    }

    return <div className="categoriesContainer">
      {catsDisplay}
    </div>
}

export default Categories
