import React, {useState, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useAuth0 } from '@auth0/auth0-react'
import * as Yup from 'yup'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { Mutation } from 'react-apollo'
import Loader from '../../helpers/loader'

const CREATE_ACCOUNT = gql`
  mutation CreateAccount($newOrganization: NewOrganization!) {
    createAccount(newOrganization: $newOrganization) {
      _id
      name
    }
  }
`

const Setup = () => {

  const {
    user,
    user: { sub: userID},
    logout
  } = useAuth0()
  const [status, setStatus] = useState('ready')


    const SignupSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Required'),
      address: Yup.string()
        .min(2, 'Too Short!')
        .max(70, 'Too Long!'),
      city: Yup.string()
        .min(2, 'Too Short!')
        .max(70, 'Too Long!'),
      state: Yup.string()
        .min(1, 'Too Short!')
        .max(30, 'Too Long!'),
      zipcode: Yup.number(),
      website: Yup.string()
        .url(),
      phone: Yup.string()
        .max(20, 'Too Long!')
    });


    const [createAccount, {data, loading, error}] = useMutation(
      CREATE_ACCOUNT,
      {
        onCompleted: (newAccount) => {
          console.log('New Account Created ', newAccount)
          window.location.reload()
        }
      }
    )

    const setupForm =
          <Formik
            initialValues={{
              name: '', address: '', city: '', state: '', zipcode: '', website: '', phone: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
                createAccount({variables: {
                  newOrganization: {...values, adminUID: userID}
                }})
            }}
          >
            { ({ isSubmitting }) => {

              let buttonStyle = isSubmitting ? {backgroundColor: 'grey'} : null

              return <Form>
                  <div className="field field-organization">
                    <div className="label">Organization *</div>
                    <Field name="name" placeholder="Organization Name / Company Name" />
                    <ErrorMessage name="name" component="div" />
                  </div>

                  <div className="field field-address">
                    <div className="label">Address *</div>
                    <Field name="address" placeholder="Address"/>
                    <ErrorMessage name="address" component="div" />
                  </div>

                  <div className="group">
                    <div className="field field-city">
                      <div className="label">City *</div>
                      <Field name="city" placeholder="City"/>
                      <ErrorMessage name="city" component="div" />
                    </div>

                    <div className="field field-state">
                      <div className="label">State *</div>
                      <Field name="state" placeholder="State"/>
                      <ErrorMessage name="state" component="div" />
                    </div>
                  </div>

                  <div className="group-b">
                    <div className="field field-zip">
                      <div className="label">Zipcode *</div>
                      <Field type="number" name="zipcode" placeholder="Zipcode"/>
                      <ErrorMessage name="zipcode" component="div" />
                    </div>
                    <div className="field field-phone">
                      <div className="label">Phone Number</div>
                      <Field name="phone" placeholder="Phone"/>
                      <ErrorMessage name="phone" component="div" />
                    </div>
                  </div>

                  <div className="field field-website">
                    <div className="label">Website</div>
                    <Field name="website" placeholder="Website"/>
                    <ErrorMessage name="website" component="div" />
                  </div>


                  <button style={{buttonStyle}} className="submit" type="submit" disabled={isSubmitting}>
                    Setup Now
                  </button>
                </Form>
              }}
          </Formik>

    let substance = null

    switch(status){
      case 'ready':
        substance = <div className="setup">
          <div className="image">
            <div className="gaugr-type">GAUGR</div>
          </div>
          <div className="content">
            <div className="preHeading">Creating account for {user.nickname}</div>
            <h1>Setup your Gaugr Tools Account</h1>
            {setupForm}
          </div>
          <button className="loginbutton" onClick={logout}>Logout</button>
        </div>
        break
      case 'loading':
        substance = <Loader class1="setup" class="loading"/>
        break
    }
    return substance
    //<h1>We're not accepting new applications for Gaugr Tools</h1>

}

export default Setup
